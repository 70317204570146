import type {CheckoutType} from "@/enums/CheckoutType";

export abstract class CheckoutMethod {
    private type: CheckoutType;

    protected constructor(type: CheckoutType) {
        this.type = type;
    }

    abstract toJson(): any;

    abstract static fromJson(doc: any): CheckoutMethod;

    abstract copy(): CheckoutMethod;

    abstract equals(other: CheckoutMethod): boolean;

    get Type() {
        return this.type;
    }

    set Type(value: CheckoutType) {
        this.type = value;
    }
}
