import {defineStore} from "pinia";
import useProfileService from "@/services/ProfileService";
import {Profile} from "@/types/Profile";

/* Services */
const profileService = useProfileService();

interface ProfileStore {
    profile: Profile | null;
}

export const useProfileStore = defineStore("profile", {
    state: (): ProfileStore => ({
        profile: null,
    }),
    getters: {
        getProfile(): Profile | null {
            return this.profile as Profile | null;
        }
    },
    actions: {
        async add(profile: Profile): Promise<void> {
            this.profile = await profileService.add(profile);
        },
        async fetchById(id: string): Promise<void> {
            this.profile = await profileService.getById(id);
        },
        async fetchByUsername(username: string): Promise<void> {
            try {
                this.profile = await profileService.getByUsername(username);
            } catch (e) { // No profile found
                console.error("Profile by the username", username, " could not be fetched", e);
                this.profile = null; // Reset the profile if it doesn't exist
            }
        },
        async edit(profile: Profile): Promise<void> {
            await profileService.edit(profile.Id, profile.toDocument());
            this.profile = profile;
        },
    }
});
