export enum BoxCondition {
    FULL = "full",
    HALF = "half",
    NONE = "none",
    REPLACEMENT = "replacement"
}

export namespace BoxCondition {
    export const keys = ["full, half, none", "replacement"];
    export const values: BoxCondition[] = [BoxCondition.FULL, BoxCondition.HALF, BoxCondition.NONE, BoxCondition.REPLACEMENT];

    export function fromString(condition: string): BoxCondition {
        switch (condition.toLowerCase()) {
            case 'full':
                return BoxCondition.FULL;
            case 'half':
                return BoxCondition.HALF;
            case 'none':
                return BoxCondition.NONE;
            case 'replaced':
                return BoxCondition.REPLACEMENT;
            default:
                throw new Error('Invalid BoxCondition: ' + condition);
        }
    }
}
