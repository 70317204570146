import {defineStore} from "pinia";
import type {Item} from "@/types/Item";
import {ItemFactory} from "@/factory/ItemFactory";
import useSearchService from "@/services/SearchService";

/* Services */
const searchService = useSearchService();

interface GroupedItemStore {
    itemGroups: Item[][];
    totalCount: number; // Total number of groups
}

// Mainly used for storing grouped items on ProfilePage (grouped by size, colorway etc.)
export const useGroupedItemStore = defineStore("groupedItem", {
    state: (): GroupedItemStore => ({
        itemGroups: [] as Item[][],
        totalCount: 0
    }),
    getters: {
        getGroups(): Item[][] {
            return this.itemGroups as Item[][];
        }
    },
    actions: {
        async setGroups(itemGroups: Item[][]) {
            this.itemGroups = itemGroups;
        },
        async setTotalCount(totalCount: number) {
            this.totalCount = totalCount;
        },
        async searchItemGroups(searchParameters: any) {
            if (!searchParameters.group_by) {
                console.error("groupedItemStore.searchItemGroups: group_by parameter is required");
                return;
            }
            const result = await searchService.search("items", searchParameters);
            this.itemGroups = ItemFactory.fromGroupedResult(result);
            this.totalCount = result.found;
            console.log("Searched item groups", this.itemGroups);
        },
    }
});
