import {createI18n} from "vue-i18n";
import * as en from "./en.json";
import * as hu from "./hu.json";

const getLanguage = () => {
    const storedLang = localStorage.getItem('lang');
    if(storedLang) {
        return storedLang;
    }
    if (typeof window !== 'undefined' && window.navigator) {
        return window.navigator.language.split('-')[0] || 'en';
    }
    return 'en'; // Fallback to 'en' if window or navigator is not available (eg. Cloud Functions)
};


const i18n = createI18n({
    locale: getLanguage(),
    fallbackLocale: 'en',
    legacy: false,
    globalInjection: true,
    runtimeOnly: false,
    messages: {
        en,
        hu
    }
})

export default i18n;
