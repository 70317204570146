import type {Drop} from "@/types/Drop";
import {defineStore} from "pinia";
import useDropService from "@/services/DropService";
import type {Item} from "@/types/Item";

/* Services */
const service = useDropService();

interface DropStore {
    drops: Drop[];
}

export const useDropStore = defineStore("drop", {
    state: (): DropStore => ({
        drops: [] as Drop[]
    }),
    getters: {
        getDrops(): Drop[] {
            return this.drops as Drop[];
        },
        getDropOfItem: (state) => (item: Item): Drop | undefined => {
            return state.drops.find(d => d.ItemIds.includes(item.Id));
        }
    },
    actions: {
        async addDrop(d: Drop) {
            const drop = await service.add(d);
            this.drops.unshift(drop);
            return drop;
        },
        async editDrop(d: Drop) {
            const drop = await service.edit(d);
            this.drops = this.drops.map(d => d.Id === drop.Id ? drop : d);
            return drop;
        },
        async fetchDropsByUserId(userId: string) {
            const drops = await service.getAllByUserId(userId);
            this.drops = drops;
        },
        async deleteDrop(drop: Drop) {
            await service.delete(drop);
            this.drops = this.drops.filter(d => d.Id !== drop.Id);
        },
        async addDropItem(dropId: string, itemId: string) {
            const updatedDrop = this.drops.find(d => d.Id === dropId)?.copy();
            if (!updatedDrop) {
                throw new Error(`Drop with ID ${dropId} not found, couldn't add item with ID ${itemId}`);
            }
            updatedDrop.addItemId(itemId);
            await service.edit(updatedDrop);
            this.drops = this.drops.map(d => d.Id === updatedDrop.Id ? updatedDrop : d);
        },
        async removeDropItem(dropId: string, itemId: string) {
            const updatedDrop = this.drops.find(d => d.Id === dropId)?.copy();
            if (!updatedDrop) {
                console.error(`Drop with ID ${dropId} not found, couldn't remove item with ID ${itemId}`);
                return;
            }
            updatedDrop.removeItemId(itemId);
            await service.edit(updatedDrop);
            this.drops = this.drops.map(d => d.Id === updatedDrop.Id ? updatedDrop : d);
        }
    }
});
