import { db, functions } from '@/firebase/firebaseServices.js'
import {collection, getDocs, addDoc, onSnapshot, where, getDoc, doc, query} from "firebase/firestore";
import {httpsCallable} from "firebase/functions";
import {Subscription} from "@/types/Subscription";

class PaymentService {
    async getProductsWithPrices(activeOnly: boolean = true) {
        const productsCollection = collection(db, 'products');
        const productsQuery = activeOnly ? query(productsCollection, where('active', '==', true)) : productsCollection;
        const productsSnap = await getDocs(productsQuery);
        let products: any = productsSnap.docs.map(doc => {
            return {
                id: doc.id,
                ...doc.data()
            }
        });
        //Get prices for each product
        for (let product of products) {
            const pricesCollection = collection(db, `products/${product.id}/prices`);
            const pricesSnap = await getDocs(pricesCollection);
            product.prices = pricesSnap.docs.map(doc => {
                return {
                    id: doc.id,
                    ...doc.data()
                }
            });
        }
        return products;
    }

    async isUserSubscribed(userId: string) {
        if (!userId) {
            throw new Error('User ID is required');
        }

        const subscriptionRef = collection(db, 'customers', userId, 'subscriptions');
        const q = query(subscriptionRef, where('status', 'in', ['trialing', 'active']));

        return new Promise<boolean>((resolve, reject) => {
            const unsubscribe = onSnapshot(q, (snap) => {
                if (snap.docs.length === 0) {
                    unsubscribe();
                    resolve(false);
                } else {
                    unsubscribe();
                    resolve(true);
                }
                unsubscribe();
            });
        });
    }

    async getSubscription(userId: string) {
        if (!userId) {
            throw new Error('User ID is required');
        }

        const subscriptionRef = collection(db, 'customers', userId, 'subscriptions');
        const q = query(subscriptionRef, where('status', 'in', ['trialing', 'active']));

        //Fetch the subscription's price ID
        const snap = await getDocs(q);
        if (snap.docs.length === 0) {
            return null; //No active subscription
        }

        // Customers are only allowed to have one subscription at a time
        const subscription = snap.docs[0].data();

        const priceRef = subscription.price; //Document reference
        const priceSnap = await getDoc(priceRef);
        console.log('Price snapshot:', priceSnap.data());
        return new Subscription(userId, priceSnap.data().metadata);
    }

    async getCheckoutUrl(userId: string, priceId: string) {
        console.log('getCheckoutUrl', userId, priceId);
        if (!userId) {
            throw new Error('User ID is required');
        }
        if (!priceId) {
            throw new Error('Price ID is required');
        }

        const checkoutSessionRef = collection(db, 'customers', userId, 'checkout_sessions');
        const docRef = await addDoc(checkoutSessionRef, {
            price: priceId,
            success_url: window.location.href + '?success=true', //Handled in CreateSubscriptionPage
            cancel_url: window.location.href,
            allow_promotion_codes: true,
            tax_id_collection: 'automatic',
        });

        return new Promise<string>((resolve, reject) => {
            const unsubscribe = onSnapshot(docRef, (snap) => {
                const {error, url} = snap.data() as {
                    error?: { message: string };
                    url?: string;
                };
                if (error) {
                    unsubscribe();
                    reject(new Error(`An error occurred: ${error.message}`));
                }
                if (url) {
                    console.log('Stripe checkout URL:', url);
                    unsubscribe();
                    resolve(url);
                }
            });
        });
    }

    async getPortalUrl(userId: string) {
        if (!userId) {
            throw new Error('User ID is required');
        }

        let dataWithUrl: any;
        try {
            const functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
            const { data } = await functionRef({
                customerId: userId,
                returnUrl: window.location.href,
            });

            //Add a type to the data
            dataWithUrl = data as {url: string};
        } catch (error) {
            console.error(error);
            throw new Error('An error occurred while creating the portal link');
        }

        return new Promise<string>((resolve, reject) => {
            if (dataWithUrl.url) {
                resolve(dataWithUrl.url);
            } else {
                reject(new Error('An error occurred while creating the portal link'));
            }
        });
    }
}

export default function usePaymentService() {
    return new PaymentService();
}
