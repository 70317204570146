import { ref } from "vue";

export const useClickOutside = (dropdownWrapperID:string) => {
  const visible = ref(false);

  const toggleVisibility = () => {
    visible.value = !visible.value;
  }

  const closeOnOutsideClick = (event: MouseEvent) => {
    if (!(event.target as HTMLElement).closest(`#${dropdownWrapperID}`)) {
      visible.value = false;
    }
  }

  return { visible, toggleVisibility, closeOnOutsideClick };
}
