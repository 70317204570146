<script setup lang="ts">

import MultiSelectBox from "@/components/filters/MultiSelectBox.vue";
import {computed, ref} from "vue";
import {verticalFractionStrToNumber} from "@/utils/verticalFractionStrToNumber";

const props = defineProps({
  fieldName: {
    type: String,
    required: true
  },
  fieldId: { // Unique ID for the field, as name can be translated
    type: String,
    required: true
  },
  options: {
    type: Array<string>,
    required: true
  }
});

//Pre-sort the options (sizes) to be sorted taking into account vertical fractions
const sortedOptions = computed(() => {
  return props.options.sort((a, b) => {
    const aFloat = verticalFractionStrToNumber(a);
    const bFloat = verticalFractionStrToNumber(b);
    return aFloat - bFloat;
  });
});

const child = ref(null);
defineExpose({
  child
});

</script>

<template>
  <MultiSelectBox ref="child" v-bind="props" :options="sortedOptions" />
</template>

<style scoped>
</style>
