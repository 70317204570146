export enum Color {
    BLACK = "black",
    GRAY = "gray",
    WHITE = "white",
    BROWN = "brown",
    TAN = "tan",
    CREAM = "cream",
    YELLOW = "yellow",
    RED = "red",
    BURGUNDY = "burgundy",
    ORANGE = "orange",
    PINK = "pink",
    PURPLE = "purple",
    BLUE = "blue",
    NAVY = "navy",
    GREEN = "green",
    KHAKI = "khaki",
    SILVER = "silver",
    GOLD = "gold",
    MULTI = "multi"
}

export namespace Color {
    export const keys = [
        "black", "gray", "white", "brown", "tan", "cream", "yellow", "red", "burgundy", "orange", "pink", "purple", "blue", "navy", "green", "khaki", "silver", "gold", "multi"
    ];

    export const values: Color[] = [
        Color.BLACK, Color.GRAY, Color.WHITE, Color.BROWN, Color.TAN,
        Color.CREAM, Color.YELLOW, Color.RED, Color.BURGUNDY, Color.ORANGE,
        Color.PINK, Color.PURPLE, Color.BLUE, Color.NAVY, Color.GREEN,
        Color.KHAKI, Color.SILVER, Color.GOLD, Color.MULTI
    ];

    export const hexMap: { [key in Color]: string } = {
        [Color.BLACK]: "#000000",
        [Color.GRAY]: "#A0A0A0",
        [Color.WHITE]: "#FFFFFF",
        [Color.BROWN]: "#A86F3E",
        [Color.TAN]: "#D9B566",
        [Color.CREAM]: "#F7E6D3",
        [Color.YELLOW]: "#FFFF00",
        [Color.RED]: "#E33748",
        [Color.BURGUNDY]: "#800020",
        [Color.ORANGE]: "#FFA500",
        [Color.PINK]: "#E188B8",
        [Color.PURPLE]: "#9961D9",
        [Color.BLUE]: "#3748E3",
        [Color.NAVY]: "#2F3E9E",
        [Color.GREEN]: "#39C339",
        [Color.KHAKI]: "#CABD7B",
        [Color.SILVER]: "#C0C0C0",
        [Color.GOLD]: "#FFD700",
        [Color.MULTI]: "#000000" // Placeholder, style applied elsewhere
    };

    export function toHex(color: Color): string {
        return hexMap[color];
    }

    export function fromString(color: string): Color {
        const colorIndex = keys.findIndex((key) => key === color.toLowerCase());
        if (colorIndex === -1) {
            throw new Error('Invalid Color: ' + color);
        }
        return values[colorIndex]; // Utilizing that the indexes correspond
    }
}
