export enum ItemCategory {
    // Shoe
    SNEAKER = "sneaker",
    RUNNING_SHOE = "running_shoe",
    TRAINING_SHOE = "training_shoe",
    BASKETBALL_SHOE = "basketball_shoe",
    FOOTBALL_SHOE = "football_shoe",
    SANDAL = "sandal",
    SLIDE = "slide",
    BOOT = "boot",
}

export namespace ItemCategory {

}
