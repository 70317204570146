//Firebase
import { db } from '@/firebase/firebaseServices.js'
import {
    collection,
    addDoc,
    where,
    getDocs,
    query,
    orderBy,
    deleteDoc,
    doc,
    updateDoc,
    getDoc
} from 'firebase/firestore';
// Other
import {Drop} from "@/types/Drop";

class DropService {
    public async add(drop: Drop): Promise<Drop> {
        const dropsSubCollection = collection(db, `drops`);
        const dropAsDocument = drop.toDocument();
        dropAsDocument.createdAt = new Date(); // Set the createdAt field to the current date
        const dropDoc = await addDoc(dropsSubCollection, dropAsDocument);
        console.log("Added drop", dropDoc.id);
        return await this.getById(dropDoc.id);
    }

    public async getById(dropId: string): Promise<Drop> {
        const dropsCollection = collection(db, 'drops');
        const dropDoc = await getDoc(doc(dropsCollection, dropId));
        return Drop.fromDocument(dropDoc);
    }

    public async getAllByUserId(userId: string): Promise<Drop[]> {
        const dropsCollection = collection(db, 'drops');
        const dropsQuery = query(dropsCollection, where("userId", "==", userId), orderBy("createdAt", "desc"));
        const dropsSnapshot = await getDocs(dropsQuery);
        const drops = dropsSnapshot.docs.map(doc => Drop.fromDocument(doc));
        return drops;
    }

    public async delete(drop: Drop): Promise<void> {
        const dropId = drop.Id;
        const dropsCollection = collection(db, 'drops');
        await deleteDoc(doc(dropsCollection, dropId));
        console.log("Deleted drop", dropId);
    }

    public async edit(drop: Drop): Promise<Drop> {
        const dropsCollection = collection(db, 'drops');
        await updateDoc(doc(dropsCollection, drop.Id), drop.toDocument());
        console.log("Edited drop", drop);
        return drop;
    }
}

export default function useDropService() {
    return new DropService();
}
