import {collection, doc, updateDoc, query, where, getDocs, getDoc, orderBy, limit, setDoc} from "firebase/firestore";
import {db} from "@/firebase/firebaseServices";
import {Profile} from "@/types/Profile";
import type {Privacy} from "@/enums/Privacy";

export class ProfileService {
    public async add(profile: Profile): Promise<Profile> {
        profile.createdAt = new Date(); // Set the createdAt field to the current date

        const profileCollection = collection(db, 'profiles');
        await setDoc(doc(profileCollection, profile.id), profile.toDocument())
            .then(() => {
                console.log("Added profile", profile.id);
            })
            .catch(error => console.error('Error adding profile:', error));
        return profile;
    }

    public async getById(id: string): Promise<any> {
        const profileCollection = collection(db, 'profiles');
        const profileDoc = await getDoc(doc(profileCollection, id));
        if (!profileDoc.exists() || !profileDoc.data()) {
            throw new Error('Profile not found');
        }
        return Profile.fromDocument(profileDoc);
    }

    public async doesUsernameExist(username: string): Promise<boolean> {
        const profileCollection = collection(db, 'profiles');
        const profileQuery = query(profileCollection, where('username', '==', username));
        const profileDoc = await getDocs(profileQuery);
        return !profileDoc.empty;
    }

    public async getByUsername(username: string): Promise<any> {
        const profileCollection = collection(db, 'profiles');
        const profileQuery = query(profileCollection, where('username', '==', username));
        const profileDoc = await getDocs(profileQuery);
        if (profileDoc.empty) {
            throw new Error('Profile not found');
        }
        return Profile.fromDocument(profileDoc.docs[0]);
    }

    public async edit(uid: string, data: Partial<Profile>): Promise<void> {
        const profileCollection = collection(db, 'profiles');
        const profileDoc = doc(profileCollection, uid);
        await updateDoc(profileDoc, data);
    }

    public async getMostSearched(lim: number, privacy: Privacy): Promise<any> {
        const profileCollection = collection(db, 'profiles');
        const profileQuery = query(profileCollection,
            where('privacy', '==', privacy),
            where('logoUrl', '!=', null), // Only get profiles with a logo (aesthetics)
            orderBy('searches', 'desc'),
            limit(lim));
        const profileDocs = await getDocs(profileQuery);
        return profileDocs.docs.map(doc => Profile.fromDocument(doc));
    }
}

export default function useProfileService() {
    return new ProfileService();
}
