import {ItemCategory} from "@/enums/ItemCategory";

export enum ItemType {
    SHOE = "shoe",
}

export namespace ItemType {
    export const keys = ["shoe"];

    export function fromString(itemType: string): ItemType {
        switch (itemType) {
            case 'Shoe' || 'shoe':
                return ItemType.SHOE;
            default:
                throw new Error('Invalid ItemType');
        }
    }

    export function getCategories(itemType: ItemType): ItemCategory[] {
        switch (itemType) {
            case ItemType.SHOE:
                return [ItemCategory.SNEAKER, ItemCategory.RUNNING_SHOE, ItemCategory.TRAINING_SHOE, ItemCategory.BASKETBALL_SHOE, ItemCategory.FOOTBALL_SHOE, ItemCategory.SANDAL, ItemCategory.SLIDE, ItemCategory.BOOT];
            default:
                throw new Error('Invalid ItemType');
        }
    }
}
