//Typesense
import { client } from '@/typesense/typesenseConfig.js'
import {ItemType} from "@/enums/ItemType";
import {Shoe} from "@/types/Shoe";

class SearchService {
    async search(collectionName: string, searchParameters: any) {
        //console.log('searching', collectionName, searchParameters);
        const result = await client.collections(collectionName).documents().search(searchParameters);
        //console.log('result', result);
        return result;
    }

    async multiSearch(searchRequests: any, commonSearchParameters: any) {
        //console.log('searching', searchParameters);
        const result = await client.multiSearch.perform(searchRequests, commonSearchParameters);
        //console.log('result', result);
        return result;
    }
}

export default function useSearchService() {
    return new SearchService();
}
