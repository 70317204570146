<script setup lang="ts">
import { ref, defineProps, defineEmits, computed, onMounted } from "vue";
import { useRouter } from "vue-router";

const props = defineProps({
  fieldName: {
    type: String,
    required: true
  },
  fieldId: { // Unique ID for the field, as name can be translated
    type: String,
    required: true
  },
  options: {
    type: Array,
    required: true
  }
});

const router = useRouter();

onMounted(() => {
  extractFromUrl();
  // If value was extracted from URL, emit it
  if (selectedOptions.value.length) {
    emits('change', selectedOptions.value);
    showOptions.value = true;
  }
});

const optionsModel = ref<{ [key: string]: boolean }>({});
const selectedOptions = computed(() => Object.keys(optionsModel.value).filter(key => optionsModel.value[key]));

const showOptions = ref(false);

const toggleShowOptions = () => {
  showOptions.value = !showOptions.value;
};

const emits = defineEmits({
  change: (selectedOptions: string[]) => true
});

const toggleOption = (option: string) => {
  optionsModel.value[option] = !optionsModel.value[option];
  emitSelectedOptions();
};

const emitSelectedOptions = () => {
  embedInUrl();
  emits('change', selectedOptions.value);
};

const embedInUrl = () => {
  if (selectedOptions.value.length) {
    const escapedOptions = selectedOptions.value.map(option => option.replace(/,/g, '%2C'));
    router.push({ query: { ...router.currentRoute.value.query, [props.fieldId]: escapedOptions.join(',') } });
  } else {
    router.push({ query: { ...router.currentRoute.value.query, [props.fieldId]: undefined } });
  }
};

const extractFromUrl = () => {
  const queryValue = router.currentRoute.value.query[props.fieldId] as string;
  if (queryValue) {
    queryValue.split(',').forEach((option: string) => {
      const unescapedOption = option.replace(/%2C/g, ',');
      optionsModel.value[unescapedOption] = true;
    });
  }
};

const deselectAll = () => {
  Object.keys(optionsModel.value).forEach(key => {
    optionsModel.value[key] = false;
  });
  emitSelectedOptions();
};

const deselectOption = (option: string) => {
  optionsModel.value[option] = false;
  emitSelectedOptions();
};

defineExpose({
  deselectAll,
  deselectOption
});
</script>

<template>
  <div @click="toggleShowOptions()" class="flex justify-between cursor-pointer">
    <div class="font-urbanist text-[17px] font-bold leading-[1.2]">{{ fieldName }}</div>
    <i class="ri-arrow-down-s-line text-[1.75rem] h-[1.25rem] text-TextDark" :class="showOptions
    ? 'arrow-open'
    : 'arrow-close'
    "></i>
  </div>
  <div class="max-h-[250px] flex flex-wrap gap-[.5rem] mt-[1.25rem] overflow-y-auto" v-if="showOptions">
    <!-- Display boxes for each option, flex wrap, 3 per row -->
    <div v-for="option in options" :key="option"
      class="py-[.625rem] px-[1rem] bg-BGSemiLight border-[1.5px] rounded-full cursor-pointer duration-[.15s] ease-in-out"
      @click="toggleOption(option as string)"
      :class="optionsModel[option as string] ? 'border-BGDark text-TextDark' : 'border-BGSemiLight text-TextSemiDark}'">
      <div class="text-[15px] leading-[1.2] cursor-pointer">{{ option }}</div>
    </div>
  </div>
</template>

<style scoped>
.arrow-open {
  transform: translateY(1px) translateX(7px) rotateX(-180deg);
  transition: transform 0.25s ease-in-out;
}

.arrow-close {
  transform: translateY(1px) translateX(7px) rotateX(0deg);
  transition: transform 0.25s ease-in-out;
}

/* width */
::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.5rem;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--dtBorder);
}
</style>
