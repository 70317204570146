<script setup lang="ts">
import { onMounted, ref } from 'vue';
import useDebounce from "@/composables/useDebounce";
import i18n from "@/locale/i18n";
import { useRouter } from "vue-router";

onMounted(() => {
  extractFromUrl();
  // If value was extracted from URL, emit it
  if (query.value) {
    emits('change', query.value);
  }
});

const emits = defineEmits({
  change: (query: string) => true
});

const router = useRouter();

const query = ref('');

const debouncedEmit = useDebounce((value: string) => {
  query.value = value;
  embedInUrl();
  emits('change', query.value);
}, 500);

const embedInUrl = () => {
  if (!query.value) {
    router.push({ query: { ...router.currentRoute.value.query, q: undefined } });
    return;
  }
  router.push({ query: { ...router.currentRoute.value.query, q: query.value } });
};

const extractFromUrl = () => {
  const q = router.currentRoute.value.query.q;
  if (query) {
    query.value = q as string;
  }
};

const clearQuery = () => {
  query.value = '';
  embedInUrl();
  emits('change', query.value);
};

defineExpose({
  clearQuery
});

const searchBarFocused = ref<boolean>(false);
</script>

<template>
  <div
    class="w-[100%] flex items-center py-[.5rem] px-[1rem] bg-BGSemiLight text-TextDark border-[1px] outline-none rounded-full duration-[.2s] ease-in-out"
    :class="searchBarFocused ? 'border-BGDark' : 'border-ltBorder'">
    <i
      class="ri-search-line text-[22px] h-[22px] flex justify-center items-center text-TextSemiDark translate-y-[.5px]"></i>
    <input type="text" class="w-[100%] h-[22px] ml-[.75rem] bg-transparent outline-none"
      :placeholder="i18n.global.t('search_bar.search')" :value="query"
      @input="event => debouncedEmit(event.target.value)" @focusin="searchBarFocused = true" @focusout="searchBarFocused = false" />
    <i v-if="query" @click.stop="clearQuery"
      class="ri-close-line text-[22px] h-[22px] flex justify-center items-center text-TextSemiDark translate-y-[.5px] cursor-pointer"></i>
  </div>
</template>

<style scoped>
::placeholder {
  color: var(--TextSemiDark);
}
</style>
