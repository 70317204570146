import {CheckoutType} from "@/enums/CheckoutType";
import {CheckoutMethod} from "@/types/CheckoutMethod";
import {InstagramCheckout} from "@/types/InstagramCheckout";
import {Privacy} from "@/enums/Privacy";

export class Profile {
    id: string; //userId
    username: string;
    private logoUrl: string | null;
    private bannerUrl: string | null;
    private profileName: string;
    private links: { url: string, title: string }[];
    private privacy: Privacy;
    //Meta
    createdAt: Date;

    // Shopping
    private checkoutMethod: CheckoutMethod | null;

    constructor(props: any) {
        this.id = props.id;
        this.logoUrl = props.logoUrl || null;
        this.bannerUrl = props.bannerUrl || null;
        this.username = props.username;
        this.profileName = props.profileName;
        this.links = props.links || [];
        this.privacy = props.privacy || Privacy.NOT_LISTED;
        this.createdAt = props.createdAt; // Not ready from TypeSense, set by the service creating the profile
        this.checkoutMethod = props.checkoutMethod || null;
    }

    //Deep copy
    copy(): Profile {
        return new Profile({
            id: this.id,
            logoUrl: this.logoUrl,
            bannerUrl: this.bannerUrl,
            username: this.username,
            profileName: this.profileName,
            links: [...this.links],
            privacy: this.privacy,
            createdAt: this.createdAt,
            checkoutMethod: this.checkoutMethod ? this.checkoutMethod.copy() : null
        });
    }

    toDocument(): any {
        return {
            userId: this.id,
            logoUrl: this.logoUrl,
            bannerUrl: this.bannerUrl,
            username: this.username,
            profileName: this.profileName,
            links: this.links,
            privacy: this.privacy,
            createdAt: this.createdAt,
            checkoutMethod: this.checkoutMethod ? this.checkoutMethod.toJson() : null
        }
    }

    static fromDocument(doc: any): Profile {
        const data = doc.data();
        if (data.checkoutMethod) {
            switch (data.checkoutMethod.type) {
                case CheckoutType.INSTAGRAM:
                    data.checkoutMethod = InstagramCheckout.fromJson(data.checkoutMethod);
                    break;
                default:
                    data.checkoutMethod = null;
            }
        }
        return new Profile({ id: doc.id, ...data});
    }

    get Id(): string {
        return this.id;
    }

    get ProfileName(): string {
        return this.profileName;
    }

    set ProfileName(value: string) {
        this.profileName = value;
    }

    get Username(): string {
        return this.username;
    }

    set Username(value: string) {
        this.username = value;
    }

    get Links(): { url: string, title: string }[] {
        return this.links;
    }

    set Links(value: { url: string, title: string }[]) {
        this.links = value;
    }

    public addLink(url: string, title: string) {
        this.links.push({ url, title });
    }

    public removeLink(index: number) {
        this.links.splice(index, 1);
    }

    get LogoUrl(): string | null {
        return this.logoUrl;
    }

    set LogoUrl(value: string | null) {
        this.logoUrl = value;
    }

    get BannerUrl(): string | null {
        return this.bannerUrl;
    }

    set BannerUrl(value: string | null) {
        this.bannerUrl = value;
    }

    get Privacy(): Privacy { return this.privacy; }

    set Privacy(value: Privacy) { this.privacy = value; }

    get CheckoutMethod(): CheckoutMethod | null {
        return this.checkoutMethod;
    }

    set CheckoutMethod(value: CheckoutMethod | null) {
        this.checkoutMethod = value;
    }
}
