import { computed, ref, watch } from 'vue';

export default function usePagination(perPage: number,
                                      totalCount: () => number
) {
    const currentPage = ref(1);

    const totalElementCount = computed(totalCount);

    const totalPages = computed(() => Math.ceil(totalElementCount.value / perPage));

    const goToPage = (page: number) => {
        if (page >= 1 && page <= totalPages.value) {
            currentPage.value = page;
        }
    };

    return {
        currentPage,
        totalPages,
        goToPage,
        itemsPerPage: perPage
    };
}
