import {defineStore} from "pinia";
import type {Item} from "@/types/Item";
import useItemService from "@/services/ItemService";
import useSearchService from "@/services/SearchService";
import {ItemFactory} from "@/factory/ItemFactory";

/* Services */
const itemService = useItemService();
const searchService = useSearchService();

interface ItemStore {
    items: Item[];
    totalCount: number;
}

export const useItemStore = defineStore("item", {
    state: (): ItemStore => ({
        items: [] as Item[],
        totalCount: 0
    }),
    getters: {
        getItems(): Item[] {
            return this.items as Item[];
        },
    },
    actions: {
        async setItems(items: Item[]) {
            this.items = items;
        },
        async setTotalCount(totalCount: number) {
            this.totalCount = totalCount;
        },
        async addItem(item: Item): Promise<Item> {
            console.log("Adding item", item);
            const newItem = await itemService.add(item);
            this.items.unshift(newItem);
            this.totalCount++;
            return newItem; // Return the new item so that the caller can use it
        },
        async fetchItemsByUserId(userId: string, page: number = 1, perPage: number = 24) {
            const searchParameters = {
                q: '*',
                query_by: '*',
                filter_by: `userId:${userId}`,
                page: page,
                per_page: perPage,
                sort_by: 'featured:desc,createdAt:desc'
            }
            const result = await searchService.search("items", searchParameters);
            this.items = ItemFactory.fromResult(result);
            this.totalCount = result.found;
            console.log("Fetched items", this.items);
        },
        async fetchItemsByUsername(username: string, page: number = 1, perPage: number = 24) {
            const searchParameters = {
                q: '*',
                query_by: '*',
                filter_by: `username:${username}`,
                page: page,
                per_page: perPage,
            }
            const result = await searchService.search("items", searchParameters);
            this.items = ItemFactory.fromResult(result);
            this.totalCount = result.found;
            console.log("Fetched items", this.items);
        },
        async searchItemsByUserId(userId: string, query: string, page: number = 1, perPage: number = 24) {
            const searchParameters = {
                q: query,
                query_by: '*',
                filter_by: `userId:${userId}`,
                page: page,
                per_page: perPage,
                sort_by: 'featured:desc,createdAt:desc'
            }
            const result = await searchService.search("items", searchParameters);
            this.items = ItemFactory.fromResult(result);
            this.totalCount = result.found;
            console.log("Searched items", this.items);
        },
        async editItem(item: Item): Promise<Item> {
            const editedItem = await itemService.edit(item);
            const index = this.items.findIndex((i) => i.Id === editedItem.Id);
            this.items[index] = editedItem;
            return editedItem; // Return the edited item so that the caller can use it
        },
        async deleteItem(item: Item) {
            await itemService.delete(item);
            this.items = this.items.filter((i) => i.Id !== item.Id);
            this.totalCount--;
        },
        async editItems(items: Item[]): Promise<Item[]> {
            const editedItems = await itemService.editAll(items);
            // Only edit the items that are loaded in the store
            for (const editedItem of editedItems) {
                const index = this.items.findIndex((i) => i.Id === editedItem.Id);
                if (index !== -1) {
                    console.log("Edited item", editedItem.Id, editedItem);
                    this.items[index] = editedItem;
                }
            }
            return editedItems;
        }
    }
});
