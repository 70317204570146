<script setup lang="ts">
import { RouterLink } from "vue-router";
import { useI18n } from "vue-i18n";
/*-- Import assets --*/
import logoPrimary from "@/assets/images/otherlet-logo.svg";
import emailIcon from "@/assets/icons/email-icon.svg";
import {getContentUrl} from "@/utils/getContentURL";
import {onMounted, ref} from "vue";

/* i18n */
const { t } = useI18n();

/* Socials */
const socials: any = [
	{
		name: "email",
		link: "mailto:otherletaps@gmail.com",
		icon: emailIcon,
	},
	{
		name: "instagram",
		link: "https://www.instagram.com/otherlet.aps/",
		icon: "ri-instagram-line",
	},
	{
		name: "facebook",
		link: "https://www.facebook.com/otherlet.aps/",
		icon: "ri-facebook-fill",
	},
];

const tosUrl = ref<string>('');
(async () => {
  tosUrl.value = await getContentUrl('otherlet/legal/tos.pdf');
})();
const privacyUrl = ref<string>('');
(async () => {
  privacyUrl.value = await getContentUrl('otherlet/legal/privacy.pdf');
})();

</script>

<template>
	<footer>
		<div class="flex flex-col gap-[4rem] pb-[2rem]">
			<div class="flex justify-between items-center gap-[1.5rem] flex-wrap">
				<!-- Logo -->
				<RouterLink to="/">
					<img :src="logoPrimary" alt="otherlet. logo" class="h-[40px] cursor-pointer lg:h-[50px]" />
				</RouterLink>
				<!-- Socials -->
				<div class="flex gap-[1rem] lg:gap-[1.125rem]">
					<div v-for="social in socials" :key="social.name">
						<div v-if="social.name === 'email'" class="button-container relative cursor-pointer">
							<a :href="social.link"
								class="relative h-[40px] w-[40px] flex justify-center items-center bg-BGLight border-[1px] border-BGDark rounded-full z-[2] lg:h-[48px] lg:w-[48px]">
								<img :src="social.icon" :alt="social.name + ' icon'"
									class="h-[17px] flex justify-center items-center lg:h-[21px]">
							</a>
							<div
								class="button-shadow absolute top-[2px] left-[2px] h-[40px] w-[40px] flex justify-center items-center bg-BGDark border-[1px] border-BGDark rounded-full z-[1] duration-[.15s] ease-in-out lg:h-[48px] lg:w-[48px]">
							</div>
						</div>
						<div v-else class="button-container relative cursor-pointer">
							<a :href="social.link" target="_blank"
								class="relative h-[40px] w-[40px] flex justify-center items-center bg-BGLight border-[1px] border-BGDark rounded-full z-[2] lg:h-[48px] lg:w-[48px]">
								<i class="text-[1.25rem] h-[20px] flex justify-center items-center text-BGDark lg:text-[1.5rem] lg:h-[24px]"
									:class="social.icon"></i>
							</a>
							<div
								class="button-shadow absolute top-[2px] left-[2px] h-[40px] w-[40px] flex justify-center items-center bg-BGDark border-[1px] border-BGDark rounded-full z-[1] duration-[.15s] ease-in-out lg:h-[48px] lg:w-[48px]">
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Legal info -->
			<div class="flex items-center gap-[1rem] pl-[.25rem] text-[.875rem] text-TextNormal lg:gap-[1.25rem]">
				<div class="">&copy;otherlet. 2024</div>
				<RouterLink :to="{ name: 'tos' }" class="underline cursor-pointer">
					{{ t('footer.tos') }}
				</RouterLink>
        <RouterLink :to="{ name: 'privacy' }" class="underline cursor-pointer">
          {{ t('footer.privacy') }}
        </RouterLink>
			</div>
		</div>
	</footer>
</template>

<style scoped>
/* Hover isn't visible on touchscreen devices */
@media (hover: hover) {
	.button-container:hover .button-shadow {
		top: 0;
		left: 0;
	}
}
</style>
