export default function useDebounce<T>(func: (value: T) => void, delay: number): (value: T) => void {
    let timeout: number | null = null;

    return (value: T) => {
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            func(value);
        }, delay);
    };
}
