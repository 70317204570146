//Hack: Remove console.log in production
if (process.env.NODE_ENV === "production") {
    //Log copyright
    console.log("©2024 otherlet. All rights reserved.");
    console.log = function () {};
}

/* Create app */
import './assets/main.css'
import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)

/* Importing global components */
// Router
import router from './router'
app.use(router)
// Pinia
import { createPinia } from 'pinia'
app.use(createPinia())
// i18n
import i18n from './locale/i18n'
app.use(i18n);
// Sentry
import * as Sentry from "@sentry/vue";
Sentry.init({
    app,
    dsn: "https://8198bc23418672a11bab7885251b0ffc@o4507279270477824.ingest.de.sentry.io/4507279272902736",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    enabled: process.env.NODE_ENV === 'production'
});
// Swiper
import { register } from 'swiper/element/bundle';
register();

app.mount('#app')
