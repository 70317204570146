/*
 *  Our JavaScript client library works on both the server and the browser.
 *  When using the library on the browser, please be sure to use the
 *  search-only API Key rather than the master API key since the latter
 *  has write access to Typesense and you don't want to expose that.
 */

import Typesense from 'typesense';

let local = new Typesense.Client({
    'nodes': [{
        'host': 'localhost', // For Typesense Cloud use xxx.a1.typesense.net
        'port': 8108,      // For Typesense Cloud use 443
        'protocol': 'http'   // For Typesense Cloud use https
    }],
    'apiKey': 'xyz', // Full access API Key
    'connectionTimeoutSeconds': 3
});

let prod = new Typesense.Client({
    'nodes': [{
        'host': 'yot6pzrkbmn5j3e1p-1.a1.typesense.net', // For Typesense Cloud use xxx.a1.typesense.net
        //'port': 433,      // For Typesense Cloud use 443 - Ádám: Well, that was a lie.
        'protocol': 'https'   // For Typesense Cloud use https
    }],
    'apiKey': 'qh1wBtdUTawilUfho81rLibAXTRoIdbJ', // Search-only items, profiles API Key (group)
    'connectionTimeoutSeconds': 5,
});

let client =  process.env.NODE_ENV === 'production' ? prod : local;

export { client }
