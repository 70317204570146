export function verticalFractionStrToNumber (fraction: string): number {
    const parts = fraction.split(' ');
    let wholeOrFraction = parts[0];
    if (parts.length === 1) {

        wholeOrFraction = wholeOrFraction.replace(',', '.'); // THIS IS A HUGE HACK, BUT THIS CAN BE DANGEROUS
        return Number(wholeOrFraction);
    }
    const verticalFraction = parts[1];
    const fractionParts = verticalFraction.split('/');
    return Number(wholeOrFraction) + Number(fractionParts[0]) / Number(fractionParts[1]);
};
