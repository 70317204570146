export enum Condition {
    NEW_WITH_TAGS = "new_with_tags",
    NEW_WITHOUT_TAGS = "new_without_tags",
    LIKE_NEW = "like_new",
    USED_EXCELLENT = "used_excellent",
    USED_VERY_GOOD = "used_very_good",
    USED_GOOD = "used_good",
    FAIR = "fair"
}

export namespace Condition {
    export const keys = [
        "new_with_tags",
        "new_without_tags",
        "like_new",
        "used_excellent",
        "used_very_good",
        "used_good",
        "fair"
    ];

    export const values: Condition[] = [
        Condition.NEW_WITH_TAGS,
        Condition.NEW_WITHOUT_TAGS,
        Condition.LIKE_NEW,
        Condition.USED_EXCELLENT,
        Condition.USED_VERY_GOOD,
        Condition.USED_GOOD,
        Condition.FAIR
    ];

    export function fromString(condition: string): Condition {
        switch (condition.toLowerCase()) {
            case 'new_with_tags':
                return Condition.NEW_WITH_TAGS;
            case 'new_without_tags':
                return Condition.NEW_WITHOUT_TAGS;
            case 'like_new':
                return Condition.LIKE_NEW;
            case 'used_excellent':
                return Condition.USED_EXCELLENT;
            case 'used_very_good':
                return Condition.USED_VERY_GOOD;
            case 'used_good':
                return Condition.USED_GOOD;
            case 'fair':
                return Condition.FAIR;
            default:
                throw new Error('Invalid Condition: ' + condition);
        }
    }
}
