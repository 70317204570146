import usePaymentService from "@/services/PaymentService";
import  {Subscription} from "@/types/Subscription";
import {defineStore} from "pinia";
import useItemService from "@/services/ItemService";

/* Services */
const paymentService = usePaymentService();
const itemService = useItemService();

interface SubscriptionStore {
    subscription: Subscription | null;
}

export const useSubscriptionStore = defineStore("subscription", {
    state: (): SubscriptionStore => ({
        subscription: null,
    }),
    getters: {
        getSubscription(): Subscription | null {
            return this.subscription as Subscription | null;
        }
    },
    actions: {
        async fetchSubscription(userId: string): Promise<Subscription | null> {
            console.log('Fetching subscription for user', userId);
            this.subscription = await paymentService.getSubscription(userId);
            console.log('Subscription fetched', this.subscription);
            return this.subscription;
        },
        canAddDrop(): boolean {
            if (!this.subscription) {
                throw new Error('Subscription not fetched');
            }
            return this.subscription.drops;
        },
        async canAddItem(): Promise<boolean> {
            if (!this.subscription) {
                throw new Error('Subscription not fetched');
            }
            // Requires a middleware to check if the user has the right to add an item
            const itemCountOfUser = await itemService.getItemCountOfUser(this.subscription.customerId);
            console.log('Item count of user', itemCountOfUser);
            console.log('Subscription items', this.subscription.items);
            console.log('Can add item?', itemCountOfUser < this.subscription.items);
            return itemCountOfUser < this.subscription.items;
        },
        async canFeatureItem(): Promise<boolean> {
            if (!this.subscription) {
                throw new Error('Subscription not fetched');
            }
            // Requires a middleware to check if the user has the right to feature an item
            const featuredItemCountOfUser = await itemService.getFeaturedItemCountOfUser(this.subscription.customerId);
            console.log('Featured item count of user', featuredItemCountOfUser);
            console.log('Subscription featured items', this.subscription.featuredItems);
            console.log('Can feature item?', featuredItemCountOfUser < this.subscription.featuredItems);
            return featuredItemCountOfUser < this.subscription.featuredItems;
        }
    }
});
