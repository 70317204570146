import { storage } from '@/firebase/firebaseServices.js';
import { getDownloadURL, ref } from 'firebase/storage';
import {toCdnUrl} from "@/utils/toCdnUrl";

export const getContentUrl = async (location: string) => {
    // If in development, return the local URL
    if (process.env.NODE_ENV === 'development') {
        console.warn("Development mode: returning local URL for", location);
        return null;
    }

    const fileRef = ref(storage, location);
    try {
        // Get the download URL
        const url = await getDownloadURL(fileRef); // Get Storage download URL
        const cdnUrl = toCdnUrl(url); // Convert to CDN URL
        return cdnUrl;
    } catch (error) {
        console.error("Error getting content download URL:", error);
        throw error;
    }
}
