export enum Privacy {
    PUBLIC = "public", // appears on hub
    NOT_LISTED = "not_listed", // can be visited by direct link
    PRIVATE = "private", // can only be visited by owner (for now, by no one)
}

export namespace Privacy {
    export const keys = ["public", "not_listed", "private"];
    export const values: Privacy[] = [Privacy.PUBLIC, Privacy.NOT_LISTED, Privacy.PRIVATE];

    export function fromString(itemType: string): Privacy {
        switch (itemType) {
            case 'public':
                return Privacy.PUBLIC;
            case 'not_listed':
                return Privacy.NOT_LISTED;
            case 'private':
                return Privacy.PRIVATE;
            default:
                throw new Error('Invalid Privacy');
        }
    }
}
