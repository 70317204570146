<script setup lang="ts">
import { computed, onMounted, ref, watch, watchEffect } from "vue";
import useDebounce from "@/composables/useDebounce";
import { useRouter } from "vue-router";
import i18n from "@/locale/i18n";

const props = defineProps({
  fieldName: {
    type: String,
    required: true
  },
  min: {
    type: Number,
    required: true
  },
  max: {
    type: Number,
    required: true
  }
});

const router = useRouter();

onMounted(() => {
  watchEffect(() => {
    if (props.min !== 0 && props.max !== 0 && !isExtracted.value) {
      extractFromUrl();
      // If value was extracted from URL, emit it
      if (minVal.value !== props.min || maxVal.value !== props.max) {
        emits('change', [minVal.value, maxVal.value]);
        showOptions.value = true; // Show the options if they were extracted from URL
      }
      isExtracted.value = true;
    }
  });
});

const emits = defineEmits({
  change: (value: [number, number]) => true
});

const isExtracted = ref(false); // So that we don't extract from URL on every render
const minValRelative = ref(0);
const maxValRelative = ref(0);

// Absolute values
const minVal = computed(() => props.min + minValRelative.value);
const maxVal = computed(() => props.max + maxValRelative.value);


// Step should be 1% difference between min and max (integers only)
const step = computed(() => Math.round((props.max - props.min) / 100));

const debouncedEmit = useDebounce((value: [number, number]) => {
  embedInUrl();
  value = [clampMin(value[0]), clampMax(value[1])];
  emits('change', value);
}, 500);

const changeMinVal = (event: Event) => {
  const newValue = Number((event.target as HTMLInputElement).value);
  if (newValue <= maxVal.value && newValue >= props.min) { // Only update if new value is in valid range
    minValRelative.value = newValue - props.min;
    debouncedEmit([minVal.value, maxVal.value]);
  } else if ((event.target as HTMLInputElement).type === 'range') { // Stop the thumb from going above maxVal
    minValRelative.value = maxVal.value - props.min;
    (event.target as HTMLInputElement).value = String(maxVal.value);
  } // Leave the rest to adjustVal function (clamping)
};

const changeMaxVal = (event: Event) => {
  const newValue = Number((event.target as HTMLInputElement).value);
  if (newValue >= minVal.value && newValue <= props.max) { // Only update if new value is in valid range
    maxValRelative.value = newValue - props.max;
    debouncedEmit([minVal.value, maxVal.value]);
  } else if ((event.target as HTMLInputElement).type === 'range') { // Stop the thumb from going below minVal
    maxValRelative.value = minVal.value - props.max;
    (event.target as HTMLInputElement).value = String(minVal.value);
  } // Leave the rest to adjustVal function (clamping)
};

const embedInUrl = () => {
  router.push({
    query: {
      ...router.currentRoute.value.query,
      max: maxVal.value === props.max ? undefined : maxVal.value,
      min: minVal.value === props.min ? undefined : minVal.value
    }
  });
};

const extractFromUrl = () => {
  const minValFromUrl = router.currentRoute.value.query.min as string;
  const maxValFromUrl = router.currentRoute.value.query.max as string;
  if (minValFromUrl) {
    minValRelative.value = Number(minValFromUrl) - props.min;
  }
  if (maxValFromUrl) {
    maxValRelative.value = Number(maxValFromUrl) - props.max;
  }
};

// Actually adjust minVal
const adjustMinVal = (event: Event) => {
  // Sync the input value with the actual value
  const newValue = Number((event.target as HTMLInputElement).value);
  const newClampedValue = clampMin(newValue);
  minValRelative.value = newClampedValue - props.min;


  // Adjust between min and maxVal
  // Check if minVal is smaller than min, if not, adjust minValRelative
  if (minVal.value < props.min) {
    minValRelative.value = 0;
  } else if (minVal.value > maxVal.value) {
    // Change it to maxVal
    minValRelative.value = maxVal.value - props.min;
  }
  console.log("minValRelative", minValRelative.value, "minVal", minVal.value)
  debouncedEmit([minVal.value, maxVal.value]);
};

// Actually adjust maxVal
const adjustMaxVal = (event: Event) => {
  // Sync the input value with the actual value
  const newValue = Number((event.target as HTMLInputElement).value);
  const newClampedValue = clampMax(newValue);
  maxValRelative.value = newClampedValue - props.max;

  // Adjust between minVal and max
  // Check if maxVal is bigger than max, if not, adjust maxValRelative
  if (maxVal.value > props.max) {
    maxValRelative.value = 0;
  } else if (maxVal.value < minVal.value) {
    // Change it to minVal
    maxValRelative.value = minVal.value - props.max;
  }
  debouncedEmit([minVal.value, maxVal.value]);
};

const clampMin = (value: number) => {
  if (value < props.min) {
    return props.min;
  } else if (value > maxVal.value) {
    return maxVal.value;
  }
  return value;
};

const clampMax = (value: number) => {
  if (value > props.max) {
    return props.max;
  } else if (value < minVal.value) {
    return minVal.value;
  }
  return value;
};

const formatCurrencySymbol = (currency: string) => {
  const lang = i18n.global.locale.value;

  const options = {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0
  };

  // Using 0 as a placeholder for formatting currency symbol
  return new Intl.NumberFormat(lang, options).format(0).replace(/\d/g, '').trim();
};

const showOptions = ref(false);

const toggleShowOptions = () => {
  showOptions.value = !showOptions.value;
};

const clearMinVal = () => {
  minValRelative.value = 0;
  embedInUrl();
  emits('change', [minVal.value, maxVal.value]);
};

const clearMaxVal = () => {
  maxValRelative.value = 0;
  embedInUrl();
  emits('change', [minVal.value, maxVal.value]);
};

const clearValues = () => {
  minValRelative.value = 0;
  maxValRelative.value = 0;
  embedInUrl();
  emits('change', [minVal.value, maxVal.value]);
};

defineExpose({
  clearValues,
  clearMinVal,
  clearMaxVal
});

</script>

<template>
  <!-- Range filter v2 -->
  <div class="cursor-default">
    <div @click="toggleShowOptions()" class="flex justify-between cursor-pointer">
      <div class="font-urbanist text-[17px] font-bold leading-[1.2]">{{ fieldName }}</div>
      <i class="ri-arrow-down-s-line text-[1.75rem] h-[1.25rem] text-TextDark" :class="showOptions
      ? 'arrow-open'
      : 'arrow-close'
      "></i>
    </div>
    <div v-if="showOptions" class="flex flex-col gap-[1.5rem] mt-[1.25rem] pb-[.5rem]">
      <div class="flex justify-between items-center gap-[.5rem]">
        <label class="py-[.5rem] px-[1rem] bg-BGSemiLight rounded-full">
          <input type="number" class="bg-BGSemiLight text-[15px] outline-none" :value="minVal" :min="min" :max="max" @input="changeMinVal"
            :step="step" @blur="adjustMinVal">
          <!-- For now, only HUF is supported -->
          {{ formatCurrencySymbol('HUF') }}
        </label>
        <label class="py-[.5rem] px-[1rem] bg-BGSemiLight rounded-full">
          <input type="number" class="bg-BGSemiLight text-[15px] outline-none" :value="maxVal" :min="min" :max="max" @input="changeMaxVal"
            :step="step" @blur="adjustMaxVal">
          <!-- For now, only HUF is supported -->
          {{ formatCurrencySymbol('HUF') }}
        </label>
      </div>
      <!-- Slider -->
      <div class="relative w-[100%] h-[2px] bg-BGDark outline-none">
        <!-- Progress bar -->
        <div ref="progress" class="h-[100%] absolute bg-BGDark rounded-full"></div>
        <!-- In these change emit is not necessary bc. text input emits change -->
        <input ref="minRange" class="input-range-min mr-[24px]" name="range_1" type="range" :min="min" :max="max"
          :value="minVal" @input="changeMinVal" :step="Math.min(step, 1)">
        <input ref="maxRange" class="input-range-max ml-[24px]" name="range_1" type="range" :min="min" :max="max"
          :value="maxVal" @input="changeMaxVal" :step="Math.min(step, 1)">
      </div>
    </div>
  </div>
</template>

<style scoped>
input[type='range']::-webkit-slider-thumb:active {
  cursor: grabbing;
}

input[type='range'] {
  position: absolute;
  top: -11px;
  background: none;
  -webkit-appearance: none;
  pointer-events: none;
  outline: none;
}

.input-range-min {
  width: calc(100% - 24px);
}

.input-range-max {
  width: calc(100% - 24px);
}

input[type='range']::-webkit-slider-thumb {
  height: 24px;
  width: 24px;
  background-color: var(--BGDark);
  border-radius: 50%;
  cursor: grab;
  -webkit-appearance: none;
  pointer-events: auto;
}

.arrow-open {
  transform: translateY(1px) translateX(7px) rotateX(-180deg);
  transition: transform 0.25s ease-in-out;
}

.arrow-close {
  transform: translateY(1px) translateX(7px) rotateX(0deg);
  transition: transform 0.25s ease-in-out;
}
</style>
