<script setup lang="ts">

import { toCdnUrl } from "@/utils/toCdnUrl";
import LinkWithIcon from "@/components/profile/LinkWithIcon.vue";
import PhotoPlaceholder from "@/components/reusable/PhotoPlaceholder.vue";
import { ref } from "vue";
import { Profile } from "@/types/Profile";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useProfileStore } from "@/stores/ProfileStore";
import { computed, onMounted, onUnmounted } from "vue";
import { useClickOutside } from "@/composables/useClickOutside";
/*-- Import Assets --*/
import img from "@/assets/images/dropstock.webp";

/* i18n */
const { t } = useI18n();

const route = useRoute();

/* Stores */
const profileStore = useProfileStore();

/* Refs */
const profileRef = ref<Profile | undefined>(profileStore.getProfile);

/* Computed */
const profileName = computed(() => profileRef.value?.ProfileName);
const bannerUrl = computed(() => profileRef.value?.BannerUrl);
const links = computed(() => profileRef.value?.Links);

/* Copy to Clipboard */
const currentLocation = computed(() => {
  return window.location.origin + '/' + route.params.username;
});

const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
  toggleMoreDropdownVisibility();
}

//Click Outside Filters Drawer
const {
  visible: isMoreDropdownOpen,
  toggleVisibility: toggleMoreDropdownVisibility,
  closeOnOutsideClick: closeMoreDropdownOnOutsideClick
} = useClickOutside("more-dropdown");

onMounted(() => {
  document.addEventListener("click", closeMoreDropdownOnOutsideClick);
});

onUnmounted(() => {
  document.removeEventListener("click", closeMoreDropdownOnOutsideClick);
});
</script>

<template>
  <div
    class="profile-card relative max-w-[351px] w-[100%] pb-[1.5rem] bg-BGLight rounded-[32px] md:max-w-[261px] md:rounded-[24px] lg:max-w-[351px] lg:rounded-[32px]"
    :class="{ 'pt-[1.5rem]': !bannerUrl }">
    <!-- Share BTN -->
    <div id="more-dropdown">
      <div @click="toggleMoreDropdownVisibility"
        class="absolute cursor-pointer h-[32px] w-[32px] flex justify-center items-center bg-BGNormal rounded-full z-[2]"
        :class="bannerUrl ? 'top-[1rem] right-[1rem]' : 'top-[1.5rem] right-[1.5rem]'">
        <i
          class="ri-more-fill text-[1.125rem] h-[18px] flex justify-center items-center text-BGDark translate-x-[.5px] translate-y-[.5px]"></i>
      </div>
      <div v-if="isMoreDropdownOpen"
        class="drop-shadow-card w-[225px] absolute flex flex-col gap-[.125rem] py-[.5rem] px-[.5rem] bg-BGLight rounded-[16px] z-[12]"
        :class="bannerUrl ? 'top-[1rem] right-[1rem]' : 'top-[1.5rem] right-[1.5rem]'">
        <!-- More Items -->
        <div @click="copyToClipboard(currentLocation)"
          class="more-item py-[9px] px-[1rem] rounded-full cursor-pointer duration-[.2s] ease-in-out">
          <div class="flex items-center gap-[.625rem] translate-y-[.5px]">
            <i class="ri-link text-[18px] h-[18px] flex justify-center items-center"></i>
            <div class="text-[.875rem] leading-tight">
              {{ t('profile_page.more.copy_link') }}
            </div>
          </div>
        </div>
      </div>
      <!-- <div v-if="isMoreDropdownOpen" class="absolute top-[1.5rem] right-[1.5rem]">
        <div
          class="w-fit relative flex flex-col items-end gap-[.5rem] py-[1rem] px-[1.5rem] bg-BGLight text-[15px] leading-[1.2] border-[1px] border-BGDark rounded-[16px] z-[2] ">
          <div v-for="(option, index) in moreOptions" :key="index" @click="" class="cursor-pointer"
            :class="option ? 'text-TextNormal' : 'text-TextDark'">{{
      t(`sort.${option}`) }}
          </div>
        </div>
        <div
          class="h-[100%] w-[100%] absolute top-[2px] left-[2px] flex flex-col items-end gap-[.5rem] py-[1rem] px-[1.5rem] bg-BGDark text-[15px] border-[1px] border-BGDark rounded-[16px] z-[1]">
        </div>
      </div> -->
    </div>
    <!-- Banner -->
    <img v-if="bannerUrl" :src="toCdnUrl(bannerUrl)" alt="Profile Banner"
      class="h-[175px] max-w-[100%] w-[100%] object-cover object-center rounded-t-[32px] rounded-b-[24px] md:h-[130px] lg:h-[175px] md:rounded-t-[24px] lg:rounded-t-[32px]">
    <div class="flex flex-col px-[1.5rem]">
      <!-- Profile Logo -->
      <div
        class="relative w-[80px] h-[80px] flex justify-center items-center rounded-full xs:w-[100px] xs:h-[100px] md:w-[80px] md:h-[80px] lg:w-[100px] lg:h-[100px]"
        :class="{ 'mt-[-40px] xs:mt-[-50px] md:mt-[-40px] lg:mt-[-50px]': toCdnUrl(bannerUrl) }">
        <img id="logo" v-if="profileRef?.LogoUrl" :src="toCdnUrl(profileRef?.LogoUrl)" alt="Profile Logo"
          class="relative bg-BGLight w-full h-full border-[1.5px] border-ltLightBorder rounded-full object-cover object-center z-[2]" />
        <div v-else
          class="w-full h-full flex justify-center items-center bg-BGLight text-[2.5rem] border-[1.5px] border-BGDark rounded-full z-[2] xs:text-[50px] md:text-[40px] lg:text-[50px]">
          <span class="translate-y-[0.5px] xs:translate-y-[1px] md:translate-y-[.5px] lg:translate-y-[1px]">{{ profileRef?.username.charAt(0).toUpperCase() }}</span>
        </div>
        <div
          class="absolute top-[2px] left-[2px] w-[80px] h-[80px] flex justify-center items-center overflow-hidden bg-BGDark border-[1px] border-BGDark z-[1] rounded-full xs:w-[100px] xs:h-[100px] xs:top-[3px] xs:left-[3px] md:w-[80px] md:h-[80px] md:top-[2px] md:left-[2px] lg:w-[100px] lg:h-[100px] lg:top-[3px] lg:left-[3px]">
        </div>
      </div>
      <!-- Profile Name -->
      <h2
        class="font-urbanist mt-[.75rem] mb-[.5rem] text-[1.25rem] font-bold leading-[1.1] xs:text-[1.5rem] md:text-[1.25rem] lg:text-[1.5rem]">
        {{ profileName }}
      </h2>
      <!-- Profile Username -->
      <div
        class="font-urbanist text-[.875rem] text-TextNormal font-medium leading-[1.1] xs:text-[15px] md:text-[.875rem] lg:text-[15px]">
        @{{ profileRef?.username }}
      </div>
      <!-- Profile Links -->
      <div class="flex flex-wrap gap-[.625rem] mt-[1.5rem]">
        <LinkWithIcon
          class="flex justify-center items-center gap-[.375rem] py-[.625rem] px-[.875rem] bg-BGSemiLight text-TextSemiDark text-[.875rem] leading-none rounded-full"
          v-for="link in links" :key="link.url" :link="link" />
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Shadow */
.drop-shadow-card {
  filter: drop-shadow(0px 5px 20px #0000001F);
}

.profile-card {
  filter: drop-shadow(0px 10px 40px #00000012);
}

/* Hover isn't visible on touchscreen devices */
@media (hover: hover) {
  .more-item:hover {
    background-color: var(--BGNormal);
  }
}
</style>
