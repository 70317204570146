import {CheckoutType} from "@/enums/CheckoutType";
import {CheckoutMethod} from "@/types/CheckoutMethod";

export class InstagramCheckout extends CheckoutMethod {
    private instagramUsername: string;
    constructor(props: any) {
        super(CheckoutType.INSTAGRAM);
        this.instagramUsername = props.instagramUsername;
    }

    toJson() {
        return {
            type: this.type,
            instagramUsername: this.instagramUsername
        }
    }

    static fromJson(json: any): InstagramCheckout {
        return new InstagramCheckout({
            instagramUsername: json.instagramUsername
        });
    }

    copy() {
        return new InstagramCheckout({
            instagramUsername: this.instagramUsername
        });
    }

    equals(other: CheckoutMethod): boolean {
        if (other instanceof InstagramCheckout) {
            return this.instagramUsername === other.instagramUsername;
        }
        return false;
    }

    get InstagramUsername() {
        return this.instagramUsername;
    }

    set InstagramUsername(value: string) {
        this.instagramUsername = value;
    }
}
