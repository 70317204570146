<script setup lang="ts">
import type { PropType } from "vue";
import { computed, ref } from "vue";
import { Item } from "@/types/Item";
import VLazyImage from "v-lazy-image";
import { toCdnUrl } from "@/utils/toCdnUrl";
import { useRouter } from "vue-router";
import i18n from "@/locale/i18n";
import {ItemType} from "@/enums/ItemType";
import {useI18n} from "vue-i18n";

/* i18n */
const { t } = useI18n();

const props = defineProps({
  items: Object as PropType<Item[]>,
});

const router = useRouter();

// Format as currency
const shownFormattedPrice = computed(() => {
  const price = shownItem.value.SalePrice;
  const currency = shownItem.value.SaleCurrency;

  return formatPrice(price, currency);
});

const formatPrice = (price: number, currency: string) => {
  const lang = i18n.global.locale.value;

  const options = {
    style: "currency",
    currency: currency,
  };

  // Check if the price has decimal places
  if (Number.isInteger(price)) {
    options.minimumFractionDigits = 0;
  }

  return new Intl.NumberFormat(lang, options).format(price);
};

const currentPhotoIndex = ref(0);
const currentPhotoUrl = computed(() => {
  return toCdnUrl(shownItem.value.PhotoUrls[currentPhotoIndex.value]);
});

const photoUrls = computed(() => {
  return shownItem.value.PhotoUrls.map(url => toCdnUrl(url));
});

const inspectItem = (item: Item) => {
  router.push({
    name: "inspect-item",
    params: {
      itemId: item.Id,
    },
  });
};

const shownItem = computed(() => {
  return props.items?.[0]; // This aligns the best with the built in sorting
  /*
  // Show first item is the first that is
  // 1. Featured
  const featured = props.items?.find(item => item.Featured);
  if (featured) return featured;
  // 2. New Drop (within 24 hours)
  const newDrops = props.items?.find(item => isNewDrop(item));
  const newestDrop = props.items?.sort((a, b) => b.DroppedAt - a.DroppedAt)[0];
  if (newDrops) return newestDrop;
  // 3. New (within 24 hours)
  const newest = props.items?.sort((a, b) => b.CreatedAt - a.CreatedAt)[0];
  if (newest && isNew(newest)) return newest;
  // 4. Cheapest
  return props.items?.sort((a, b) => a.SalePrice - b.SalePrice)[0];
   */
});

// Wheteher it should have the 'New Drop' flair (within 24 hours)
const isNewDrop = (item: Item) => {
  return item.DroppedAt && new Date(item.DroppedAt) > new Date(Date.now() - 24 * 60 * 60 * 1000);
};

// Whether it should have the 'New' flair (within 24 hours)
const isNew = (item: Item) => {
  return new Date(item.CreatedAt) > new Date(Date.now() - 24 * 60 * 60 * 1000);
};

const getFlairText = (item: Item) => {
  if (item.Featured) return t("flair.featured");
  if (isNewDrop(item)) return t("flair.new_drop");
  if (isNew(item)) return t("flair.new");
  return;
};

</script>

<template>
  <RouterLink :to="{name: 'inspect-item', params: { itemId: shownItem?.Id, }, }" @click="inspectItem(shownItem!)" class="item-card cursor-pointer" @mouseleave="currentPhotoIndex = 0">
    <div class="relative">
      <v-lazy-image :src="currentPhotoUrl"
                    src-placeholder="https://placehold.co/400x400/F2F2F3/F2F2F3/webp"
                    use-picture
                    :alt="shownItem?.Name"
        class="w-full aspect-square object-cover">
      </v-lazy-image>
      <!-- Flair -->
      <!-- New / New Drop -->
      <div v-if="getFlairText(shownItem)" class="absolute top-[.5rem] right-[.5rem]">
        <div
            class="relative h-fit w-fit flex justify-center items-center bg-BGDark rounded-full z-[2]">
          <span
              class="flex justify-center items-center translate-x-[1px] mx-[.5rem] my-[.25rem] text-BGLight">
            {{ getFlairText(shownItem) }}
          </span>
        </div>
      </div>
      <!-- Primary button with arrow -->
      <div class="absolute bottom-[.5rem] right-[.5rem]">
        <div
          class="relative h-[36px] w-[36px] flex justify-center items-center bg-BGLight border-[1px] border-BGDark rounded-full z-[2]">
          <i
            class="ri-arrow-right-s-line text-[1.5rem] h-[20px] flex justify-center items-center text-BGDark translate-x-[1px]"></i>
        </div>
        <div
          class="button-shadow absolute top-[2px] left-[2px] h-[36px] w-[36px] flex justify-center items-center bg-BGDark border-[1px] border-BGDark rounded-full z-[1] duration-[.2s] ease-in-out">
        </div>
      </div>
    </div>
    <div class="min-h-[125px] flex flex-col pt-[1rem] sm:min-h-[150px] md:min-h-[156px] lg:min-h-[160px]">
      <div v-if="photoUrls.length > 0" class="item-card-photos h-0 hidden gap-[.5rem] mb-0 opacity-0">
        <div v-for="(currentPhotoUrl) in photoUrls" :key="currentPhotoUrl" class="item-card-photo duration-[.2s] ease-in-out">
          <v-lazy-image :src="currentPhotoUrl" src-placeholder="https://placehold.co/125x125/F2F2F3/F2F2F3/webp"
                        use-picture
                        :alt="shownItem?.Name"
                        @mouseover="currentPhotoIndex = photoUrls.indexOf(currentPhotoUrl)"
            class="object-cover aspect-square">
          </v-lazy-image>
        </div>
      </div>
      <div class="flex flex-col gap-[.5rem]">
        <h4 class="text-[1.125rem] font-semibold text-TextDark leading-[1.2] overflow-hidden">
          {{ shownItem?.Name }}
        </h4>
        <p class="item-card-text mb-[1px] text-[15px] text-TextNormal leading-none">
          {{ shownItem?.Brand }}
        </p>
      </div>
      <div class="flex gap-[0.5rem] mt-[1.125rem]">
        <p class="text-[17px] font-medium leading-none">{{ shownFormattedPrice }}</p>
        <!--
        <p v-if="!isAllSamePrice" class="text-[17px] text-TextNormal line-through leading-none">
          {{mostExpensiveFormattedPrice }}
        </p>
        -->
      </div>
    </div>
  </RouterLink>
</template>

<style scoped>
.item-card-photo {
  width: calc((100% - 40px) / 6 );
}
/* Hover isn't visible on touchscreen devices */
@media (hover: hover) {
  .item-card:hover .button-shadow {
    top: 0;
    left: 0;
  }

  .item-card:hover .item-card-text {
    display: none;
  }

  .item-card:hover .item-card-photos {
    display: flex;
    margin-bottom: 1.125rem;
    height: fit-content;
    visibility: visible;
    opacity: 1;
    transition: all 0.2s ease-in-out;
  }
}
</style>
