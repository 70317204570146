import { auth } from '@/firebase/firebaseServices.js';
import { createUserWithEmailAndPassword, updateProfile, signInWithEmailAndPassword } from 'firebase/auth';

class AuthService {
    // onUserCreate - CF that will create user document in Firestore
    public async registerWithEmailAndPassword(email: string, password: string) {
        await createUserWithEmailAndPassword(auth, email, password);
        return auth.currentUser; //TODO: Check if this is the correct return value
    }

    public async loginWithEmailAndPassword(email: string, password: string) {
        const userCredentials = await signInWithEmailAndPassword(auth, email, password);
        return userCredentials.user;
    }

    public async setDisplayName(displayName: string) {
        await updateProfile(auth.currentUser, { displayName });
    }

    public async logout() {
        await auth.signOut();
    }
}

export default function useAuthService() {
    return new AuthService();
}
