// Relevant metadata for a subscription (gotten from price of a subscription):
export class Subscription {
    customerId: string;
    items: number;
    drops: boolean;
    featuredItems: number;

    constructor(customerId: string, props: any) {
        console.log('Subscription props:', props);
        this.customerId = customerId
        this.items = Number(props.items)
        this.drops = (props.drops === 'true')
        this.featuredItems = Number(props.featured_items)
    }
}
