import {FirebaseTimestampToDate} from "@/utils/firebaseTimestampToDate";

export class Drop {
    private id: string;
    private userId: string; // The user who created the drop
    private name: string;
    private itemIds: string[]; // The items in the drop
    private published: boolean; // Whether the drop is published or not

    // Meta
    private readonly createdAt: Date;

    constructor(props: any) {
        this.id = props.id;
        this.userId = props.userId;
        this.name = props.name;
        this.itemIds = props.itemIds || [];
        this.published = props.published || false;
        this.createdAt = props.createdAt || undefined; // Should be set by the server (now set in service)
    }

    static fromDocument(doc: any): Drop {
        const data = doc.data();
        return new Drop({
            id: doc.id,
            ...data,
            createdAt: FirebaseTimestampToDate(data.createdAt),
        });
    }

    toDocument(): any {
        return {
            userId: this.userId,
            name: this.name,
            itemIds: this.itemIds,
            published: this.published,
            createdAt: this.createdAt,
        };
    }

    copy(): Drop {
        const props = {
            id: this.id,
            userId: this.userId,
            name: this.name,
            itemIds: [...this.itemIds],
            published: this.published,
            createdAt: this.createdAt
        }
        return new Drop(props);
    }

    // Getters & Setters
    get Id(): string {return this.id;}
    set Id(value: string) {this.id = value;}

    get UserId(): string {return this.userId;}
    set UserId(value: string) {this.userId = value;}

    get Name(): string {return this.name;}
    set Name(value: string) {this.name = value;}

    get ItemIds(): string[] {return this.itemIds;}
    set ItemIds(value: string[]) {this.itemIds = value;}
    addItemId(itemId: string) {this.itemIds.push(itemId);}
    removeItemId(itemId: string) {this.itemIds = this.itemIds.filter(id => id !== itemId);}

    get Published(): boolean {return this.published;}
    set Published(value: boolean) {this.published = value;}
}
