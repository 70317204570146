<script setup lang="ts">
import { ref, defineProps, defineEmits, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { Color } from "@/enums/Color";
import {useRouter} from "vue-router";

/* i18n */
const { t } = useI18n();

const props = defineProps({
  fieldName: {
    type: String,
    required: true
  },
  fieldId: { // fieldName might be translated
    type: String,
    required: true
  },
  modelValue: { // Selected colors
    type: Array as () => Color[],
    required: false,
    default: () => []
  },
  colors: { // Available colors
    type: Array as () => Color[],
    required: false,
    default: () => []
  },
});

const showOptions = ref(false);

const toggleShowOptions = () => {
  showOptions.value = !showOptions.value;
};

const emits = defineEmits(['update:modelValue']);

const selectedColors = computed({
  get: () => props.modelValue,
  set: (value) => {
    emits('update:modelValue', value);
    embedInUrl(value);
  }
});

const isSelected = (color: Color) => selectedColors.value.includes(color);

const add = (color: Color) => {
  if (selectedColors.value.includes(color)) return;
  selectedColors.value = [...selectedColors.value, color];
}

const remove = (color: Color) => {
  selectedColors.value = selectedColors.value.filter((c) => c !== color);
}

// Embedding in URL

const router = useRouter();

onMounted(() => {
  const extractedColors = extractFromUrl();
  if (extractedColors.length) {
    selectedColors.value = extractedColors;
    showOptions.value = true; // Show options if there are selected colors on page load
  }
});

const embedInUrl = (colors: Color[]) => {
  const selectedColorsStrArr = colors.map((color) => color.toString());
  if (selectedColorsStrArr.length) {
    router.push({ query: { ...router.currentRoute.value.query, [props.fieldId]: selectedColorsStrArr.join(',') } });
  } else {
    router.push({ query: { ...router.currentRoute.value.query, [props.fieldId]: undefined } });
  }
};

const extractFromUrl = () => {
  const selectedColorsStrArr = router.currentRoute.value.query[props.fieldId] as string;
  if (selectedColorsStrArr) {
    return selectedColorsStrArr.split(',').map((color) => Color.fromString(color)) as Color[];
  }
  return [];
};

const deselectAll = () => {
  selectedColors.value = [];
};

const deselectColor = (color: Color) => {
  selectedColors.value = selectedColors.value.filter((c) => c !== color);
};

defineExpose({
  deselectAll,
  deselectColor
});
</script>

<template>
  <div @click="toggleShowOptions()" class="flex justify-between cursor-pointer">
    <div class="font-urbanist text-[17px] font-bold leading-[1.2]">{{ t('profile_page.color') }}</div>
    <i class="ri-arrow-down-s-line text-[1.75rem] h-[1.25rem] text-TextDark" :class="showOptions
    ? 'arrow-open'
    : 'arrow-close'
    "></i>
  </div>
  <div v-if="showOptions" class="max-h-[250px] flex flex-wrap gap-[.5rem] mt-[1.25rem] ml-[1px] overflow-y-auto">
    <div v-for="(color, index) in colors" :key="index" @click="isSelected(color) ? remove(color) : add(color)"
      class="w-fit flex items-center gap-[.625rem] py-[8px] px-[1rem] border-[1px] border-ltBorder rounded-full duration-[.2s] ease-in-out cursor-pointer"
      :style="{ borderColor: isSelected(color) ? 'var(--BGDark)' : 'var(--ltBorder)' }">
      <div class="w-[20px] h-[20px] relative bg-BGLight border-[1px] rounded-full duration-[.2s] ease-in-out"
        :style="{ borderColor: isSelected(color) ? 'var(--BGDark)' : 'transparent' }">
        <div
          class="min-w-[14px] min-h-[14px] w-full h-full absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] border-[1px] rounded-full duration-[.2s] ease-in-out"
          :class="{ 'bg-multicolor': color === Color.MULTI }" :style="{
    width: isSelected(color) ? '14px' : '20px', height: isSelected(color) ? '14px' : '20px',
    backgroundColor: Color.toHex(color),
    borderColor: color !== Color.WHITE && color !== Color.MULTI ? Color.toHex(color) : color === Color.MULTI ? 'var(--ltBorder)' : 'var(--dtBorder)'
  }">
        </div>
      </div>
      <div class="text-[15px] leading-tight translate-y-[.5px]">
        {{ t(`color.${color}`) }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.arrow-open {
  transform: translateY(1px) translateX(7px) rotateX(-180deg);
  transition: transform 0.25s ease-in-out;
}

.arrow-close {
  transform: translateY(1px) translateX(7px) rotateX(0deg);
  transition: transform 0.25s ease-in-out;
}

/* width */
::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.5rem;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--dtBorder);
}

/* Multicolor background */
.bg-multicolor {
  background: conic-gradient(from 0.25turn,
      rgb(224, 32, 32),
      /* Red */
      rgb(250, 100, 0) 0.17turn,
      /* Orange */
      rgb(247, 181, 0) 0.33turn,
      /* Yellow */
      rgb(109, 212, 0) 0.5turn,
      /* Lime Green */
      rgb(0, 145, 255) 0.67turn,
      /* Blue */
      rgb(98, 54, 255) 0.83turn,
      /* Indigo */
      rgb(174, 34, 227) 0.96turn,
      /* Violet */
      rgb(182, 32, 224)
      /* End Violet */
    );
  background-size: 200% 200%;
  background-position: center;
}
</style>
