<script setup lang="ts">
/*-- Import components --*/
import FooterSection from "@/components/landing/FooterSection.vue";
import { useClickOutside } from "@/composables/useClickOutside";

import { computed, onMounted, onUnmounted, ref } from "vue";
import SortBySelect from "@/components/filters/SortBySelect.vue";
import SortByList from "@/components/filters/SortByList.vue";
import SearchBar from "@/components/filters/SearchBar.vue";
import MultiSelectList from "@/components/filters/MultiSelectList.vue";
import ColorMultiSelectBox from "@/components/filters/ColorMultiSelectBox.vue";
import DoubleRangeFilter from "@/components/filters/DoubleRangeSlider.vue";
import { useRoute, useRouter } from "vue-router";
import useSearchService from "@/services/SearchService";
import { ItemStatus } from "@/enums/ItemStatus";
import usePagination from "@/composables/usePagination";
import PaginationControls from "@/components/reusable/PaginationControls.vue";
import i18n from "@/locale/i18n";
import GroupedItemCard from "@/components/profile/GroupedItemCard.vue";
import { useGroupedItemStore } from "@/stores/GroupedItemStore";
import ProfileHeader from "@/components/profile/ProfileHeader.vue";
import SizeMultiSelectBox from "@/components/filters/SizeMultiSelectBox.vue";
import { useI18n } from "vue-i18n";
import { Condition } from "@/enums/Condition";
import useDebounce from "@/composables/useDebounce";
import { Shoe } from "@/types/Shoe";
import {Color} from "@/enums/Color";

const route = useRoute();
const router = useRouter();

/* i18n */
const { t } = useI18n();


const def = Shoe.base();

const shoe = ref(def.copy());

/* Stores  */
const groupedItemStore = useGroupedItemStore();

/* Services */
const searchService = useSearchService();

/* Lifecycle */
onMounted(() => {
  initTitle();

  // Set the page to the one in the URL
  const page = Number(route.params.page) || 1;

  Promise.all([
    initBrandFilter(),
    initSizeFilter(),
    initPriceFilter(),
    initConditionFilter(),
    initColorFilter(),
  ])
    .then(() => {
      areFiltersInitialized.value = true;
      filter(page);
    });
});

/* Refs */
//Selected filters
const isActiveFiltersOpen = ref(true);
//Filter components
const searchBar = ref(null);
const brandFilter = ref(null);
const sizeFilter = ref(null);
const priceFilter = ref(null);
const conditionFilter = ref(null);
const colorFilter = ref(null);
// Filters
const queryRef = ref('');
const brandsRef = ref<string[]>([]);
const selectedBrandsRef = ref<string[]>([]);
const sizesRef = ref<string[]>([]);
const selectedSizesRef = ref<string[]>([]);
const minPriceRef = ref<number>(0);
const minPriceValRef = ref<number>(0);
const maxPriceRef = ref<number>(0);
const maxPriceValRef = ref<number>(0);
const conditionsRef = ref<Condition[]>([]);
const selectedConditionsRef = ref<Condition[]>([]);
const colorsRef = ref<Color[]>([]);
const selectedColorsRef = ref<Color[]>([]);

const areFiltersInitialized = ref(false); // This is to deny filtering before filters are initialized

//Sort By
const sortOptions = ref([
  "featured",
  "newest",
  "oldest",
  "price_asc",
  "price_desc"
]);
const sortBy = ref('featured');

const sortOptionAsTypesense = {
  "featured": "featured:desc,createdAt:desc,salePrice:asc",
  "newest": "createdAt:desc,salePrice:asc",
  "oldest": "createdAt:asc,salePrice:asc",
  "price_asc": "salePrice:asc,createdAt:desc",
  "price_desc": "salePrice:desc,createdAt:desc"
};
const sortByAsTypesense = computed(() => sortOptionAsTypesense[sortBy.value]);

/* Computed */
//Profile
const username = computed(() => route.params.username);
//Items
const groupedItems = computed(() => groupedItemStore.getGroups);

const totalCount = computed(() => groupedItemStore.totalCount);
//Filters
const brands = computed(() => brandsRef.value.sort()); // Sorted for clarity
const selectedBrands = computed(() => selectedBrandsRef.value);
const sizes = computed(() => sizesRef.value); // TODO: EU size is the default size for now
const selectedSizes = computed(() => selectedSizesRef.value);
const minPrice = computed(() => minPriceRef.value);
const maxPrice = computed(() => maxPriceRef.value);
const conditions = computed(() => Condition.values.filter(condition => conditionsRef.value.includes(condition))); // Sorted for clarity
const selectedConditions = computed(() => selectedConditionsRef.value);
const colors = computed(() => Color.values.filter(color => colorsRef.value.includes(color))); // Sorted for clarity
const selectedColors = computed(() => selectedColorsRef.value);

const activeFilters = computed(() => {
  let flattenedArray = [];

  if (queryRef.value) {
    flattenedArray.push({ field: 'query', value: queryRef.value });
  }

  // Flatten the brands
  selectedBrands.value.forEach(brand => {
    flattenedArray.push({ field: 'brand', value: brand });
  });

  // Flatten the sizes
  selectedSizes.value.forEach(size => {
    flattenedArray.push({ field: 'size', value: size });
  });

  // Flatten the prices
  if (minPriceValRef.value !== minPriceRef.value || maxPriceValRef.value !== maxPriceRef.value) {
    flattenedArray.push({
      field: 'price',
      value: `${formatPrice(minPriceValRef.value, 'HUF')} - ${formatPrice(maxPriceValRef.value, 'HUF')}`
    });
  }

  // Flatten the conditions
  selectedConditions.value.forEach(condition => {
    flattenedArray.push({ field: 'condition', value: condition });
  });

  // Flatten the colors
  selectedColors.value.forEach(color => {
    flattenedArray.push({field: 'color', value: color});
  });

  return flattenedArray;
});

/* Methods */
const initTitle = () => {
  document.title = username.value + " - otherlet.";
}

// Filters
const initBrandFilter = async () => {
  const searchParameters = {
    q: '*',
    query_fields: 'brand',
    filter_by: 'username:' + username.value + ' && status:' + ItemStatus.AVAILABLE,
    group_by: 'brand',
    group_limit: 1, // Limiting to 1 group to ensure distinct brands
  };

  await searchService.search('items', searchParameters).then(response => {
    const brands = response.grouped_hits.map((group: any) => group.group_key[0]);
    brandsRef.value = brands;
  });
}

const initSizeFilter = async () => {
  const searchParameters = {
    q: '*',
    query_fields: 'size',
    filter_by: 'username:' + username.value + ' && status:' + ItemStatus.AVAILABLE,
    group_by: 'sizes.EU', // TODO: Grouping for EU size only for now
    group_limit: 1, // Limiting to 1 group to ensure distinct sizes
    per_page: 100, // TODO: This is a temporary fix to get all sizes
  };

  await searchService.search('items', searchParameters).then(response => {
    const sizes = response.grouped_hits.map((group: any) => group.group_key[0]);
    sizesRef.value = sizes;
  });
}

const initPriceFilter = async () => {
  let searchRequests = {
    searches: [
      {
        collection: 'items',
        sort_by: 'salePrice:asc',
      },
      {
        collection: 'items',
        sort_by: 'salePrice:desc',
      }
    ]
  };

  const commonSearchParameters = {
    q: '*',
    query_fields: 'salePrice',
    filter_by: 'username:' + username.value + ' && status:' + ItemStatus.AVAILABLE,
    per_page: 1,
  };

  await searchService.multiSearch(searchRequests, commonSearchParameters).then(responses => {
    const minPriceResult = responses.results[0].hits[0].document.salePrice;
    const maxPriceResult = responses.results[1].hits[0].document.salePrice;
    minPriceRef.value = Number(minPriceResult);
    minPriceValRef.value = Number(minPriceResult);
    maxPriceRef.value = Number(maxPriceResult);
    maxPriceValRef.value = Number(maxPriceResult);
  });
}

const initConditionFilter = async () => {
  const searchParameters = {
    q: '*',
    query_fields: 'condition',
    filter_by: 'username:' + username.value + ' && status:' + ItemStatus.AVAILABLE,
    group_by: 'condition',
    group_limit: 1, // Limiting to 1 group to ensure distinct conditions
  };

  await searchService.search('items', searchParameters).then(response => {
    const conditions = response.grouped_hits.map((group: any) => group.group_key[0]);
    conditionsRef.value = conditions;
  });
}

const initColorFilter = async () => {
  const searchParameters = {
    q: '*',
    query_fields: 'colors',
    filter_by: 'username:' + username.value + ' && status:' + ItemStatus.AVAILABLE,
    facet_by: 'colors',
  };

  await searchService.search('items', searchParameters).then(response => {
    const colors = response.facet_counts[0].counts.map((count: any) => Color.fromString(count.value));
    colorsRef.value = colors;
  });
}

const translateConditionPipe = (condition: Condition) => {
  return t(`condition.${condition}`);
}

const consumeSortBy = (sort: string) => {
  // sortBy.value = sort; is done by v-model
  filterThenScrollUp();
}

const consumeQuery = (query: string) => {
  queryRef.value = query;
  filterThenScrollUp();
}

const consumeBrand = (brands: string[]) => {
  selectedBrandsRef.value = brands;
  filterThenScrollUp();
}

const consumeSize = (sizes: string[]) => {
  selectedSizesRef.value = sizes;
  filterThenScrollUp();
}

const consumePrice = (prices: [number, number]) => {
  minPriceValRef.value = prices[0];
  maxPriceValRef.value = prices[1];
  filterThenScrollUp();
}

const consumeCondition = (conditions: Condition[]) => {
  selectedConditionsRef.value = conditions;
  filterThenScrollUp();
}

const filterThenScrollUp = (onlyOnMobile: boolean = true) => {
  filter().then(() => {
    if (onlyOnMobile && window.innerWidth < 768) {
      scrollUp();
    }
  });
}

const filter = async (page: number = 1) => {
  if (!areFiltersInitialized.value) {
    return;
  }

  let filter_by = 'username:' + username.value + ' && status:' + ItemStatus.AVAILABLE
  if (selectedBrandsRef.value.length > 0) filter_by += ' && ' + 'brand:[' + selectedBrandsRef.value + ']';
  const sizes = selectedSizesRef.value.map(size => `\`${size}\``).join(','); //Escape commas
  if (selectedSizesRef.value.length > 0) filter_by += ' && ' + `sizes.EU:=[${sizes}]`;
  if (minPriceValRef.value !== minPriceRef.value || maxPriceValRef.value !== maxPriceRef.value) filter_by += ' && ' + 'salePrice:[' + minPriceValRef.value + '..' + maxPriceValRef.value + ']';
  if (selectedConditionsRef.value.length > 0) filter_by += ' && ' + 'condition:[' + selectedConditionsRef.value.map(condition => condition).join(',') + ']';
  if (selectedColorsRef.value.length > 0) filter_by += ' && ' + 'colors:[' + selectedColorsRef.value.map(color => color).join(',') + ']';

  await groupedItemStore.searchItemGroups({
    q: queryRef.value,
    query_by: '*',
    filter_by: filter_by,
    exclude_fields: 'buyPrice, buyCurrency',
    page: page,
    per_page: perPage,
    group_by: 'name,brand,colorway',
    sort_by: sortByAsTypesense.value
  }).then(() => {
    showPage(page);
  });
}

const isAnyFilterActive = () => {
  return queryRef.value
      || selectedBrandsRef.value.length > 0
      || selectedSizesRef.value.length > 0
      || minPriceValRef.value !== minPriceRef.value
      || maxPriceValRef.value !== maxPriceRef.value
      || selectedConditionsRef.value.length > 0
      || selectedColorsRef.value.length > 0;
}

//Pagination
const perPage = 24;

const {
  currentPage,
  totalPages,
  goToPage,
} = usePagination(perPage, () => totalCount.value);

const loadPage = (page: number) => {
  router.push({
    name: 'profile-page',
    params: { page },
    query: { ...router.currentRoute.value.query }
  });
  goToPage(page);
  filter(page).then(() => {
    scrollUp(); // Always scroll up after changing the page
  });
}

const showPage = (page: number) => {
  router.push({
    name: 'profile-page',
    params: { page },
    query: { ...router.currentRoute.value.query }
  });
  goToPage(page);
}

const scrollUp = () => {
  const targetComponent = document.getElementById('right-column');
  if (targetComponent) {
    targetComponent.scrollIntoView({ behavior: 'instant', block: 'start' });
  }
}

//Click Outside Filters Drawer
const {
  visible: isDrawerVisible,
  toggleVisibility: toggleDrawerVisibility,
  closeOnOutsideClick: closeDrawerOnOutsideClick
} = useClickOutside("filters-drawer");

//Screen width for sortby
const screenWidth = ref<number>(window.innerWidth); // Get initial screen width

function handleResize() {
  screenWidth.value = window.innerWidth; // Update screen width on resize
}

onMounted(() => {
  document.addEventListener("click", closeDrawerOnOutsideClick);
  window.addEventListener("resize", handleResize);
});

onUnmounted(() => {
  document.removeEventListener("click", closeDrawerOnOutsideClick);
  window.removeEventListener("resize", handleResize);
});

const inactivateFilter = (f: { field: string, value: string }) => {
  switch (f.field) {
    case 'query':
      searchBar!.value.clearQuery();
      break;
    case 'brand':
      brandFilter!.value.deselectOption(f.value);
      break;
    case 'size':
      sizeFilter!.value.child.deselectOption(f.value);
      break;
    case 'price':
      priceFilter!.value.clearValues();
      break;
    case 'condition':
      conditionFilter!.value.deselectOption(f.value);
      break;
    case 'color':
      colorFilter!.value.deselectColor(f.value);
      break;
  }
}

const formatPrice = (price: number, currency: string) => {
  const lang = i18n.global.locale.value;

  const options = {
    style: "currency",
    currency: currency,
  };

  // Check if the price has decimal places
  if (Number.isInteger(price)) {
    options.minimumFractionDigits = 0;
  }

  return new Intl.NumberFormat(lang, options).format(price);
};

// Swipe filter drawer
// Swipe functionality

const swiping = ref(false);
const startX = ref(0);
const currentX = ref(0);
const threshold = 50; // Minimum swipe distance to close the drawer

const startDrawerTouch = (event: TouchEvent) => {
  // Do not start on range input
  if (event.target instanceof HTMLElement && event.target.closest('input[type="range"]')) {
    return;
  }
  swiping.value = true;
  startX.value = event.touches[0].clientX;
  currentX.value = startX.value;
};

const handleDrawerTouchMove = (event: TouchEvent) => {
  if (!swiping.value) {
    return;
  }
  currentX.value = event.touches[0].clientX;
};

const endDrawerTouch = () => {
  if (!swiping.value) {
    return;
  }
  swiping.value = false;
  const diff = currentX.value - startX.value;
  if (diff > threshold) {
    toggleDrawerVisibility();
  }
  // Reset the drawer position
};

</script>

<template>
  <main
    class="min-h-screen flex flex-col justify-between pt-[2rem] px-[1rem] sxs:px-[.5rem] md:pt-0 md:pl-[1rem] lg:pl-[2rem] lg:pr-[1.5rem] xxxl:px-0">
    <div
      class="w-[100%] flex flex-col items-center gap-[1rem] md:flex-row md:items-stretch md:gap-[1.5rem] lg:gap-[2rem] xxl:max-w-[1456px] xxl:gap-[2.5rem] xxl:mx-auto xxxxl:max-w-[1920px]">
      <!-- Left column -->
      <section
        class="w-[100%] flex flex-col gap-[2.5rem] md:max-w-[259px] md:gap-[1.5rem] md:pt-[2rem] lg:max-w-[359px]">
        <!-- Profile card -->
        <ProfileHeader id="profileCard" class="mx-auto z-[4] md:ml-0" />
        <!-- Filters drawer -->
        <div id="filters-drawer"
          class="shadow-lg w-[100%] flex fixed top-0 right-0 bottom-0 z-[10] px-[1rem] bg-BGLight overflow-y-auto ease-in-out duration-[.2s] sm:w-fit md:h-[100vh] md:sticky md:bottom-auto md:pt-[1rem] md:pl-[4px] md:pr-[7px] md:shadow-none md:overflow-y-auto md:z-[3]"
          :class="isDrawerVisible ? 'translate-x-0' : 'translate-x-[100%] md:translate-x-0'"
          @touchstart="startDrawerTouch" @touchmove="handleDrawerTouchMove" @touchend="endDrawerTouch">
          <div class="w-[100%] flex flex-col gap-[1.25rem] sm:w-[340px] md:w-[250px] lg:w-[340px]">
            <div
              class="sticky top-0 flex items-center bg-BGLight pt-[1.5rem] pb-[1.5rem] border-b-[1px] border-ltBorder z-[3] md:hidden">
              <!-- Filter drawer title -->
              <!-- Filter drawer close btn -->
              <div @click="toggleDrawerVisibility" class="button-container relative cursor-pointer mr-[-36px]">
                <div
                  class="relative h-[36px] w-[36px] flex justify-center items-center bg-BGLight border-[1px] border-BGDark rounded-full z-[2]">
                  <i
                    class="ri-close-line text-[22px] h-[22px] flex justify-center items-center text-BGDark translate-y-[.5px]"></i>
                </div>
                <div
                  class="button-shadow absolute top-[2px] left-[2px] h-[36px] w-[36px] flex justify-center items-center bg-BGDark border-[1px] border-BGDark rounded-full z-[1] duration-[.15s] ease-in-out">
                </div>
              </div>
              <h3 class="drawer-title font-urbanist text-[1.125rem] font-bold text-TextDark leading-[1.1]">
                {{ $t('profile_page.filter_and_sort') }}
              </h3>
            </div>
            <!--  Delete Filters -->
            <div class="flex flex-col gap-[1.25rem]">
              <div class="flex justify-between items-center cursor-pointer"
                @click="isActiveFiltersOpen = !isActiveFiltersOpen">
                <div class="font-urbanist text-[17px] font-bold leading-[1.2]">
                  {{ t('profile_page.active_filters') }}
                  <span class="text-[.875rem] font-medium text-TextNormal sm:text-[15px] lg:text-[1rem]">
                    ({{ activeFilters.length }})
                  </span>
                </div>
                <!-- Fuck me if I can implement this
                <button @click="console.log('Delete all filters')" v-if="activeFilters.length > 0"
                        class="text-[.875rem] text-TextNormal leading-[1.2] underline cursor-pointer translate-y-[1px]">Összes
                  törlése</button>
                -->
                <i v-if="activeFilters.length > 0" class="ri-arrow-down-s-line text-[1.75rem] h-[1.25rem] text-TextDark"
                  :class="isActiveFiltersOpen
            ? 'arrow-open'
            : 'arrow-close'
            "></i>
              </div>
              <div v-if="isActiveFiltersOpen && activeFilters.length > 0" class="flex gap-[.625rem] flex-wrap">
                <div v-for="filter in [...activeFilters]" @click.stop="inactivateFilter(filter)"
                  class="flex items-center gap-[.25rem] py-[.625rem] pl-[1rem] pr-[.625rem] bg-BGSemiLight rounded-full cursor-pointer">
                  <div class="text-[15px] leading-[1.3] flex">
                    <!-- Found no more elegant way to apply the pipe, this is needed bc of the translations -->
                    {{
                      filter.field === 'condition'
                          ? translateConditionPipe(filter.value)
                      : filter.field === 'color' ?
                          $t(`color.${filter.value}`)
                          : filter.value
                    }}
                  </div>
                  <i class="ri-close-line text-[20px] h-[20px] flex justify-center items-center text-BGDark"></i>
                </div>
              </div>
            </div>
            <!-- Sort by -->
            <div v-if="screenWidth < 768" class="pt-[1.25rem] border-t-[1px] border-ltBorder">
              <SortByList :sort-options="sortOptions" v-model="sortBy" @update:model-value="consumeSortBy" />
            </div>
            <!-- Search bar -->
            <div class="pt-[1.25rem] border-t-[1px] border-ltBorder">
              <SearchBar ref="searchBar" @change="consumeQuery" />
            </div>
            <!-- Brand multiselect -->
            <div class="pt-[1.25rem] border-t-[1px] border-ltBorder">
              <MultiSelectList ref="brandFilter" :field-name="i18n.global.t('profile_page.brand')" :field-id="'brand'"
                :options="brands" @change="consumeBrand" />
            </div>
            <!-- Size multiselect -->
            <div class="pt-[1.25rem] border-t-[1px] border-ltBorder">
              <SizeMultiSelectBox ref="sizeFilter" :field-name="i18n.global.t('profile_page.size')" :field-id="'size'"
                :options="sizes" @change="consumeSize" />
            </div>
            <!-- Color filter -->
            <div v-if="colors.length > 1" class="pt-[1.25rem] border-t-[1px] border-ltBorder">
              <ColorMultiSelectBox ref="colorFilter" :field-name="i18n.global.t('item.colors')" :field-id="'color'"
                                   v-model="selectedColorsRef" :colors="colors" @update:model-value="filterThenScrollUp"/>
            </div>
            <!-- Range filter -->
            <div class="pt-[1.25rem] border-t-[1px] border-ltBorder">
              <DoubleRangeFilter id="priceFilter" ref="priceFilter" :field-name="i18n.global.t('profile_page.price')"
                :min="minPrice" :max="maxPrice" @change="consumePrice" />
            </div>
            <div v-if="conditions.length > 1" class="pt-[1.25rem] border-t-[1px] border-ltBorder">
              <MultiSelectList ref="conditionFilter" :field-name="i18n.global.t('item.condition')"
                :field-id="'condition'" :options="conditions" @change="consumeCondition"
                :pipe="translateConditionPipe" />
            </div>
            <div v-if="screenWidth < 768" class="w-full sticky bottom-0 left-0 flex justify-center mt-auto py-[1.25rem] border-t-[1px] border-ltBorder bg-BGLight">
              <button @click="toggleDrawerVisibility"
                class="py-[.625rem] px-[1rem] bg-BGDark text-[15px] text-TextLight leading-[1.3] rounded-full cursor-pointer">
                {{ $t('profile_page.apply') }}
              </button>
            </div>
          </div>
        </div>
      </section>
      <!-- Right column -->
      <section id="right-column" class="w-[100%] flex flex-col md:pt-[1rem]">
        <!-- Title & filter controls -->
        <div id="filter-controls-wrapper"
          class="w-[100%] sticky top-0 flex justify-between items-center py-[1rem] sxs:px-[.5rem] bg-BGLight z-[3]">
          <h3
            class="font-urbanist text-[1.5rem] font-bold text-TextDark leading-[1.1] sm:text-[1.75rem] lg:text-[2rem]">
            {{ t('item.shoe._name', 2) }}
            <span class="text-[.875rem] font-medium text-TextNormal sm:text-[15px] lg:text-[1rem]">
              ({{ totalCount }})
            </span>
          </h3>
          <!-- Sort By on smaller screens -->
          <div v-if="screenWidth < 768" @click.stop="toggleDrawerVisibility"
            class="button-container relative cursor-pointer">
            <div
              class="h-[36px] w-[36px] relative flex justify-center items-center gap-[.5rem] bg-BGLight text-[14px] font-medium text-TextDark border-[1px] border-BGDark rounded-full z-[2] duration-[.15s] ease-in-out xs:h-auto xs:w-fit xs:py-[9px] xs:pl-[1.125rem] xs:pr-[.875rem]">
              <span class="hidden translate-y-[1px] leading-[1.2] xs:flex">{{
            $t('profile_page.filter_and_sort')
          }}</span>
              <i
                class="ri-filter-3-line text-[18px] h-[18px] flex justify-center items-center text-BGDark translate-y-[.5px]"></i>
            </div>
            <div
              class="button-shadow h-[100%] w-[100%] absolute top-[2px] left-[2px] flex justify-center items-center bg-BGDark text-[15px] font-medium border-[1px] border-BGDark rounded-full z-[1] duration-[.15s] ease-in-out xs:py-[9px] xs:pl-[1.125rem] xs:pr-[.875rem]">
            </div>
          </div>
          <!-- Sort By on larger screens -->
          <div v-if="screenWidth > 768">
            <SortBySelect :sort-options="sortOptions" v-model="sortBy" @update:model-value="consumeSortBy" />
          </div>
        </div>
        <div class="flex flex-row-reverse items-start gap-[1rem] mt-[1rem] md:flex-row lg:gap-[1.5rem]">
          <div id="card-container" class="w-[100%] flex justify-start flex-wrap">
            <GroupedItemCard
              class="pb-[2rem] sxs:w-[50%] sxs:px-[.5rem] sm:pb-[1rem] lg:sm:pb-[1.5rem] xl:w-[33.33%] xxxxl:w-[25%]"
              v-for="items in groupedItems" :key="items[0].Id" :items="items" />
            <div v-if="groupedItems.length === 0"
              class="w-[100%] flex flex-col justify-center items-center text-TextNormal text-[14px] italic">
              {{ t('profile_page.no_items') }}
              <span v-if="isAnyFilterActive()">{{ t('profile_page.adjust_filters') }}</span>
            </div>
          </div>
        </div>
        <!-- Pagination -->
        <div v-if="totalCount > perPage" class="mt-[.5rem] mx-auto lg:mt-[1rem]">
          <PaginationControls :current="currentPage" :total="totalPages" @goTo="loadPage" />
        </div>
      </section>
    </div>
    <div class="w-[100%] xxl:max-w-[1456px] xxl:mx-auto xxxxl:max-w-[1920px]">
      <FooterSection class="pt-[8rem] sxs:px-[.5rem] md:pl-0 md:pr-[.5rem] lg:pt-[10rem] xxxl:pr-0" />
    </div>
  </main>
</template>

<style scoped>
.drawer-title {
  margin-left: auto;
  margin-right: auto;
}

/* Hover isn't visible on touchscreen devices */
@media (hover: hover) {
  .button-container:hover .button-shadow {
    top: 0;
    left: 0;
  }
}

/* width */
::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.5rem;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--dtBorder);
}

.arrow-open {
  transform: translateY(1px) translateX(7px) rotateX(-180deg);
  transition: transform 0.25s ease-in-out;
}

.arrow-close {
  transform: translateY(1px) translateX(7px) rotateX(0deg);
  transition: transform 0.25s ease-in-out;
}
</style>
