export enum ItemStatus {
    AVAILABLE = "available",
    NOT_AVAILABLE = "not_available",
    SOLD = "sold",
    PENDING = "pending",
}

export namespace ItemStatus {
    export const keys = ["available", "not_available", "sold", "pending"];

    export function fromString(status: string): ItemStatus {
        switch (status.toLowerCase()) {
            case 'available':
                return ItemStatus.AVAILABLE;
            case 'not available':
                return ItemStatus.NOT_AVAILABLE;
            case 'sold':
                return ItemStatus.SOLD;
            case 'pending':
                return ItemStatus.PENDING;
            default:
                throw new Error('Invalid ItemStatus: ' + status);
        }
    }
}
