<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from 'vue';

const screenW = ref(window.innerWidth);
const thresholdMini = 310; // xs breakpoint
const threshold = 340; // sm breakpoint

onMounted(() => {
  window.addEventListener('resize', () => {
    screenW.value = window.innerWidth;
  });
});

const props = defineProps({
  total: {
    type: Number,
    required: true,
  },
  current: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['goTo']);

const goTo = (page: number) => {
  if (page === props.current) return;
  if (page < 1 || page > props.total) return;
  emit('goTo', page);
};

const previous = () => {
  if (props.current === 1) return;
  goTo(props.current - 1);
};

const next = () => {
  if (props.current === props.total) return;
  goTo(props.current + 1);
};

const visiblePages = computed(() => { //TODO: Figure out what should be the behaviour with 4 buttons
  const pages = [];
  if (props.total === 3) {
    pages.push(2);
  }
  if (props.total === 4) {
    if (screenW.value <= threshold) {
      pages.push(Math.min(Math.max(2, props.current), props.total - 1));
    } else {
      pages.push(2, 3);
    }
  }

  if (props.total > 4) {
    if (props.current <= 2) {
      pages.push(2);
      if (screenW.value >= threshold) pages.push(3);
    }

    if (props.current === 3) {
      if (screenW.value >= threshold) pages.push(2);
    }

    if (props.current > 2 && props.current < props.total - 1) {
      pages.push(props.current);
    }

    if (props.current === props.total - 2) {
      if (screenW.value >= threshold) pages.push(props.total - 1);
    }

    if (props.current >= props.total - 1) {
      if (screenW.value >= threshold) pages.push(props.total - 2);
      pages.push(props.total - 1);
    }
  }
  return pages;
});

/* Triple dots */

// Backward
const showBackwardTripleDot = computed(() => {
  return props.current > 3
    && props.total > 4
    && (screenW.value >= threshold || props.current > props.total / 2)
    && !(screenW.value <= thresholdMini);
});

const backwardInputRef = ref<HTMLInputElement | null>(null);
const isBackwardInput = ref(false);
const backwardInput = ref('');

const disableBackwardInput = () => {
  isBackwardInput.value = false;
  backwardInput.value = '';
};

const enableBackwardInput = () => {
  isBackwardInput.value = true;
  nextTick(() => {
    backwardInputRef.value!.focus();
  });
};

const submitPageInputFromBackward = () => {
  submitPageInput(backwardInput.value);
  disableBackwardInput();
};

// Forward

const showForwardTripleDot = computed(() => {
  return (props.total > 4 && props.current < props.total - 2)
    && props.total > 4
    && (screenW.value >= threshold || props.current <= props.total / 2)
    && !(screenW.value <= thresholdMini);
});

const isForwardInput = ref(false);
const forwardInput = ref('');
const disableForwardInput = () => {
  isForwardInput.value = false;
  forwardInput.value = '';
};

const forwardInputRef = ref<HTMLInputElement | null>(null);

const enableForwardInput = () => {
  isForwardInput.value = true;
  nextTick(() => {
    forwardInputRef.value!.focus();
  });
};

const submitPageInputFromForward = () => {
  submitPageInput(forwardInput.value);
  disableForwardInput();
};

// Submit page input

const submitPageInput = (input: string) => {
  if (input === '') return;
  const page = parseInt(input);
  if (isNaN(page)) return;
  //Clamp the page number
  if (page < 1) goTo(1);
  else if (page > props.total) goTo(props.total);
  else goTo(page);
};



</script>

<template>
  <div class="flex items-center gap-[.5rem]">
    <!-- Previous -->
    <div class="button-container relative cursor-pointer" :class="{
      'cursor-not-allowed opacity-40': !current || current === 1,
      'cursor-pointer': current !== 1
    }" @click="previous">
      <div
        class="relative min-h-[28px] min-w-[28px] w-fit flex justify-center items-center leading-none text-[.875rem] bg-BGLight text-TextDark border-[1px] border-BGDark rounded-[6px] z-[2] duration-[.15s] ease-in-out">
        <i class="ri-arrow-left-s-line text-[18px] h-[18px] text-TextDark"></i>
      </div>
      <div
        class="min-h-[28px] min-w-[28px] w-fit absolute top-[2px] left-[2px] flex justify-center items-center leading-none text-[.875rem] bg-BGDark text-transparent border-[1px] border-BGDark rounded-[6px] z-[1] duration-[.15s] ease-in-out"
        :class="{ 'button-shadow': current !== 1 }">
      </div>
    </div>
    <!-- First page -->
    <div v-if="total > 0" @click="goTo(1)" class="button-container relative cursor-pointer">
      <div
        class="relative min-h-[28px] min-w-[28px] w-fit flex justify-center items-center leading-none text-[.875rem]  border-[1px] border-BGDark rounded-[6px] z-[2] duration-[.15s] ease-in-out"
        :class="current === 1 ? 'bg-BGDark text-TextLight' : 'bg-BGLight text-TextDark'">
        1
      </div>
      <div
        class="button-shadow min-h-[28px] min-w-[28px] w-fit absolute top-[2px] left-[2px] flex justify-center items-center leading-none text-[.875rem]  text-transparent border-[1px] border-BGDark rounded-[6px] z-[1] duration-[.15s] ease-in-out"
        :class="current === 1 ? 'bg-BGLight' : 'bg-BGDark'">
      </div>
    </div>
    <!-- Backwards page input -->
    <div v-if="showBackwardTripleDot && !isBackwardInput" @click="enableBackwardInput"
      class="button-container relative cursor-pointer">
      <div
        class="relative min-h-[28px] min-w-[28px] w-fit flex justify-center items-center leading-none text-[.875rem] bg-BGLight text-TextDark border-[1px] border-BGDark rounded-[6px] z-[2] duration-[.15s] ease-in-out">
        ...
      </div>
      <div
        class="button-shadow min-h-[28px] min-w-[28px] w-fit absolute top-[2px] left-[2px] flex justify-center items-center leading-none text-[.875rem] bg-BGDark text-transparent border-[1px] border-BGDark rounded-[6px] z-[1] duration-[.15s] ease-in-out">
      </div>
    </div>
    <input v-else-if="isBackwardInput" v-model="backwardInput" type="number" @blur="submitPageInputFromBackward"
      @keydown.enter="submitPageInputFromBackward" ref="backwardInputRef"
      class="relative min-h-[28px] min-w-[28px] max-w-[28px] flex justify-center items-center text-center leading-none text-[.875rem] bg-BGLight text-TextDark border-[1px] border-BGDark rounded-[6px] outline-none z-[2] duration-[.15s] ease-in-out cursor-pointer" />
    <!-- Pages between -->
    <div v-for="index in visiblePages" :key="index" @click="goTo(index)"
      class="button-container relative cursor-pointer">
      <div
        class="relative min-h-[28px] min-w-[28px] w-fit flex justify-center items-center leading-none text-[.875rem]  border-[1px] border-BGDark rounded-[6px] z-[2] duration-[.15s] ease-in-out"
        :class="current === index ? 'bg-BGDark text-TextLight' : 'bg-BGLight text-TextDark'">
        {{ index }}
      </div>
      <div
        class="button-shadow min-h-[28px] min-w-[28px] w-fit absolute top-[2px] left-[2px] flex justify-center items-center leading-none text-[.875rem]  text-transparent border-[1px] border-BGDark rounded-[6px] z-[1] duration-[.15s] ease-in-out"
        :class="current === index ? 'bg-BGLight' : 'bg-BGDark'">
      </div>
    </div>
    <!-- Forward page input -->
    <div v-if="showForwardTripleDot && !isForwardInput" @click="enableForwardInput"
      class="button-container relative cursor-pointer">
      <div
        class="relative min-h-[28px] min-w-[28px] w-fit flex justify-center items-center leading-none text-[.875rem] bg-BGLight text-TextDark border-[1px] border-BGDark rounded-[6px] z-[2] duration-[.15s] ease-in-out">
        ...
      </div>
      <div
        class="button-shadow min-h-[28px] min-w-[28px] w-fit absolute top-[2px] left-[2px] flex justify-center items-center leading-none text-[.875rem] bg-BGDark text-transparent border-[1px] border-BGDark rounded-[6px] z-[1] duration-[.15s] ease-in-out">
      </div>
    </div>
    <input v-else-if="isForwardInput" v-model="forwardInput" type="number" @blur="submitPageInputFromForward"
      @keydown.enter="submitPageInputFromForward" ref="forwardInputRef"
      class="relative min-h-[28px] min-w-[28px] max-w-[28px] flex justify-center items-center text-center leading-none text-[.875rem] bg-BGLight text-TextDark border-[1px] border-BGDark rounded-[6px] outline-none z-[2] duration-[.15s] ease-in-out cursor-pointer" />

    <!-- Last page -->
    <div v-if="total > 1" @click="goTo(total)" class="button-container relative cursor-pointer">
      <div
        class="relative min-h-[28px] min-w-[28px] w-fit flex justify-center items-center leading-none text-[.875rem]  border-[1px] border-BGDark rounded-[6px] z-[2] duration-[.15s] ease-in-out"
        :class="current === total ? 'bg-BGDark text-TextLight' : 'bg-BGLight text-TextDark'">
        {{ total }}
      </div>
      <div
        class="button-shadow min-h-[28px] min-w-[28px] w-fit absolute top-[2px] left-[2px] flex justify-center items-center leading-none text-[.875rem]  text-transparent border-[1px] border-BGDark rounded-[6px] z-[1] duration-[.15s] ease-in-out"
        :class="current === total ? 'bg-BGLight' : 'bg-BGDark'">
      </div>
    </div>
    <!-- Next -->
    <div class="button-container relative cursor-pointer" :class="{
      'cursor-not-allowed opacity-40': current === total,
      'cursor-pointer': current !== total
    }" @click="next">
      <div
        class="relative min-h-[28px] min-w-[28px] w-fit flex justify-center items-center leading-none text-[.875rem] bg-BGLight text-TextDark border-[1px] border-BGDark rounded-[6px] z-[2] duration-[.15s] ease-in-out">
        <i class="ri-arrow-right-s-line text-[18px] h-[18px] text-TextDark"></i>
      </div>
      <div
        class="min-h-[28px] min-w-[28px] w-fit absolute top-[2px] left-[2px] flex justify-center items-center leading-none text-[.875rem] bg-BGDark text-transparent border-[1px] border-BGDark rounded-[6px] z-[1] duration-[.15s] ease-in-out"
        :class="{ 'button-shadow': current !== total }">
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Hover isn't visible on touchscreen devices */
@media (hover: hover) {
  .button-container:hover .button-shadow {
    top: 0;
    left: 0;
  }
}
</style>
