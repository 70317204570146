import { db } from '@/firebase/firebaseServices.js'
import {collection, doc, getDoc, updateDoc, onSnapshot, query, where, getDocs} from "firebase/firestore";
import {User} from "@/types/User";

class UserService {
    public async getById(id: string): Promise<User> {
        const usersCollection = collection(db, 'users');
        const userDoc = await getDoc(doc(usersCollection, id));
        if (!userDoc.exists() || !userDoc.data()) {
            throw new Error('User not found');
        }
        return User.fromDocument(userDoc);
    }

    public async getByUsername(username: string): Promise<User> {
        const usersCollection = collection(db, 'users');
        const userQuery = query(usersCollection, where('username', '==', username));
        const userDoc = await getDocs(userQuery);
        if (userDoc.empty) {
            throw new Error('User not found');
        }
        return User.fromDocument(userDoc.docs[0]);
    }

    public async getOneSnapshot(uid: string, callback: (user: User) => void): Promise<void> {
        const usersCollection = collection(db, 'users');
        const userDoc = doc(usersCollection, uid);

        // Use onSnapshot to listen for changes in the document
        const unsubscribe = onSnapshot(userDoc, (snapshot) => {
            if (snapshot.exists()) {
                if (snapshot.data()) {
                    const user = User.fromDocument(snapshot);
                    callback(user);
                    // Unsubscribe after receiving the first result
                    unsubscribe();
                }
            }
        });
    }

    public async edit(uid: string, data: Partial<User>): Promise<void> {
        const usersCollection = collection(db, 'users');
        const userDoc = doc(usersCollection, uid);
        await updateDoc(userDoc, data);
    }
}

export default function useUserService() {
    return new UserService();
}
