import {defineStore} from "pinia";
import type {User} from "@/types/User";
import useAuthService from "@/services/AuthService";
import useUserService from "@/services/UserService";

import {auth} from "@/firebase/firebaseServices.js";

/* Services */
const authService = useAuthService();
const userService = useUserService();

interface UserStore {
    user: User | null;
}

export const useUserStore = defineStore("user", {
    state: (): UserStore => ({
        user: null,
    }),
    getters: {
        getUser(): User | null {
            return this.user as User | null;
        }
    },
    actions: {
        async register(email: string, password: string): Promise<void> {
            await authService.registerWithEmailAndPassword(email, password);
            //TODO: Fetch user document from Firestore via snapshot and set state
        },
        async login(email: string, password: string): Promise<void> {
            console.log("Logging in with email and password...")
            const firebaseAuthUser = await authService.loginWithEmailAndPassword(email, password);
            console.log("Logged in with email and password, id:", firebaseAuthUser.uid);
            await this.fetchUser(firebaseAuthUser.uid);
        },
        async logout(): Promise<void> {
            await authService.logout();
            this.user = null;
            window.location.reload(); //Redirect to login page
        },
        async fetchUser(id: string): Promise<void> {
            this.user = await userService.getById(id);
        },
        async editUser(user: User): Promise<void> {
            await userService.edit(user.Id, user.toDocument());
            this.user = user;
        },
        //TODO: Move this into authService with a callback
        async initAuthState(): Promise<void> {
            return new Promise<void>((resolve, reject) => {
                // Return unsubscribe function to allow manual unsubscription if needed
                return auth.onAuthStateChanged(async (u) => {
                    if (!u) {
                        reject(new Error("No signed-in user found, couldn't initialize auth state"));
                        return;
                    }
                    try {
                        await this.fetchUser(u.uid)
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                });
            });
        },
    }
});
