import {FirebaseTimestampToDate} from "@/utils/firebaseTimestampToDate";

export class User {
    private id: string;
    private email: string;
    private username: string;
    //Metadata
    private readonly createdAt: Date;

    constructor(props: any) {
        this.id = props.id;
        this.email = props.email;
        this.username = props.username;
        this.createdAt = props.createdAt;
    }

    toDocument(): any {
        return {
            email: this.email,
            username: this.username,
            createdAt: this.createdAt
        }
    }

    static fromDocument(doc: any): User {
        const data = doc.data();
        return new User({ id: doc.id, ...data});
    }

    // Getters & Setters
    get Id(): string {
        return this.id;
    }

    set Id(value: string) {
        this.id = value;
    }

    get Email(): string {
        return this.email;
    }

    set Email(value: string) {
        this.email = value;
    }

    get Username(): string {
        return this.username;
    }

    set Username(value: string) {
        this.username = value;
    }
}
