<script setup lang="ts">
import { ref, defineProps, defineEmits, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import i18n from "@/locale/i18n";

const props = defineProps({
  fieldName: {
    type: String,
    required: true
  },
  fieldId: { // fieldName might be translated
    type: String,
    required: true
  },
  options: {
    type: Array,
    required: true
  },
  // Pipe function to transform the the displayed value
  pipe: {
    type: Function,
    default: (value: string) => value,
  }
});

const router = useRouter();

onMounted(() => {
  extractFromUrl();
  // If value was extracted from URL, emit it
  if (selectedOptions.value.length) {
    emits('change', selectedOptions.value);
    showOptions.value = true;
  }
});

const optionsModel = ref<{ [key: string]: boolean }>({});
const selectedOptions = computed(() => Object.keys(optionsModel.value).filter(key => optionsModel.value[key]));

const showOptions = ref(false);

const toggleShowOptions = () => {
  showOptions.value = !showOptions.value;
};

const emits = defineEmits({
  change: (selectedOptions: string[]) => true
});

const emitSelectedOptions = () => {
  embedInUrl();
  emits('change', selectedOptions.value);
  console.log("selected op", selectedOptions.value);

};

const embedInUrl = () => {
  if (selectedOptions.value.length) {
    router.push({ query: { ...router.currentRoute.value.query, [props.fieldId]: selectedOptions.value.join(',') } });
  } else {
    router.push({ query: { ...router.currentRoute.value.query, [props.fieldId]: undefined } });
  }
};

const extractFromUrl = () => {
  const selectedOptions = router.currentRoute.value.query[props.fieldId] as string;
  if (selectedOptions) {
    selectedOptions.split(',').forEach((option: string) => {
      optionsModel.value[option] = true;
    });
  }
};

const deselectAll = () => {
  for (const option in optionsModel.value) {
    optionsModel.value[option] = false;
  }
  emitSelectedOptions();
};

const deselectOption = (option: string) => {
  optionsModel.value[option] = false;
  emitSelectedOptions();
};

defineExpose({
  deselectAll,
  deselectOption
});
</script>

<template>
  <div @click="toggleShowOptions()" class="flex justify-between cursor-pointer">
    <div class="font-urbanist text-[17px] font-bold leading-[1.2]">{{ fieldName }}</div>
    <i class="ri-arrow-down-s-line text-[1.75rem] h-[1.25rem] text-TextDark" :class="showOptions
    ? 'arrow-open'
    : 'arrow-close'
    "></i>
  </div>
  <div v-if="showOptions" class="max-h-[250px] flex flex-col gap-[.625rem] mt-[1.25rem] ml-[1px] overflow-y-auto">
    <div v-for="(option, index) in options" :key="index">
      <div class="flex items-center gap-[.625rem]">
        <input type="checkbox" :id="'option_' + index" :value="option"
          class="checkbox w-[22px] h-[22px] border-[1px] rounded-[4px]"
          :class="optionsModel[option as string] ? 'border-BGDark' : 'border-dtBorder'"
          v-model="optionsModel[option as string]" @change="emitSelectedOptions">
        <label :for="'option_' + index" class="text-[15px] text-TextDark">{{ pipe(option) }}</label>
      </div>
    </div>
  </div>
</template>

<style scoped>
.arrow-open {
  transform: translateY(1px) translateX(7px) rotateX(-180deg);
  transition: transform 0.25s ease-in-out;
}

.arrow-close {
  transform: translateY(1px) translateX(7px) rotateX(0deg);
  transition: transform 0.25s ease-in-out;
}

/* width */
::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.5rem;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--dtBorder);
}
</style>
