// src/composables/useScreenDimensions.ts
import { ref, onMounted, onUnmounted } from 'vue';

export function useScreenDimensions() {
    const width = ref<number>(window.innerWidth); // Get initial screen width
    const height = ref<number>(window.innerHeight); // Get initial screen height

    function handleResize() {
        width.value = window.innerWidth; // Update screen width on resize
        height.value = window.innerHeight; // Update screen height on resize
    }

    // Add resize event listener when component is mounted
    onMounted(() => {
        window.addEventListener("resize", handleResize);
    });

    // Remove resize event listener when component is unmounted
    onUnmounted(() => {
        window.removeEventListener("resize", handleResize);
    });

    return { width, height };
}
