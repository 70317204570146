<script setup lang="ts">
import { ref, defineProps, defineEmits, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

/* i18n */
const { t } = useI18n();

const props = defineProps({
  sortOptions: {
    type: Array,
    required: true,
  },
  modelValue: {
    type: String,
    required: true,
  },
});
const emits = defineEmits(["update:modelValue"]);

const router = useRouter();

const showOptions = ref(true);

const sort = ref(props.modelValue);

const toggleShowOptions = () => {
  showOptions.value = !showOptions.value;
};

const selectOption = (option: string) => {
  sort.value = option;
  emits("update:modelValue", option);
  embedInUrl();
};

const extractFromUrl = () => {
  const sortParam = decodeURIComponent(router.currentRoute.value.query.sort as string || "");
  if (sortParam && props.sortOptions.includes(sortParam)) {
    sort.value = sortParam;
  }
};

const embedInUrl = () => {
  if (sort.value) {
    router.push({ query: { ...router.currentRoute.value.query, sort: encodeURIComponent(sort.value) } });
  } else {
    router.push({ query: { ...router.currentRoute.value.query, sort: undefined } });
  }
};

onMounted(() => {
  extractFromUrl();
  embedInUrl();
  if (sort.value !== props.modelValue) {
    emits("update:modelValue", sort.value);
  }
});

</script>

<template>
  <div @click="toggleShowOptions()" class="flex justify-between cursor-pointer">
    <div class="font-urbanist text-[17px] font-bold leading-[1.2]">{{ t('sort.title') }}</div>
    <i
        class="ri-arrow-down-s-line text-[1.75rem] h-[1.25rem] text-TextDark"
        :class="showOptions ? 'arrow-open' : 'arrow-close'"
    ></i>
  </div>
  <div v-if="showOptions" class="max-h-[250px] flex flex-col gap-[.625rem] mt-[1.25rem] ml-[1px] overflow-y-auto">
    <div v-for="(option, index) in sortOptions" :key="index">
      <div class="flex items-center gap-[.625rem]">
        <input
            type="radio"
            :id="'option_' + index"
            name="sortBy"
            :value="option"
            class="radio w-[22px] h-[22px] border-[1px] rounded-full checked:bg-BGDark"
            :checked="modelValue === option"
            @change="selectOption(option)"
        />
        <label :for="'option_' + index" class="text-[15px] text-TextDark">{{ t(`sort.${option}`) }}</label>
      </div>
    </div>
  </div>
</template>

<style scoped>
.arrow-open {
  transform: translateY(1px) translateX(7px) rotateX(-180deg);
  transition: transform 0.25s ease-in-out;
}

.arrow-close {
  transform: translateY(1px) translateX(7px) rotateX(0deg);
  transition: transform 0.25s ease-in-out;
}

/* width */
::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.5rem;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--dtBorder);
}
</style>
