<script setup lang="ts">
import { ref, onMounted, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useClickOutside } from "@/composables/useClickOutside";

const router = useRouter();

/* i18n */
const { t } = useI18n();

const props = defineProps({
  sortOptions: {
    type: Array,
    required: true
  },
  modelValue: {
    type: String,
    required: true
  }
});
const emits = defineEmits(['update:modelValue']);

//Click Outside Filters Drawer
const {
  visible: isDropdownOpen,
  toggleVisibility: toggleDropdownVisibility,
  closeOnOutsideClick: closeDropdownOnOutsideClick
} = useClickOutside("dropdown");

onMounted(() => {
  extractFromUrl(); // Check if there is already a state defined in the URL
  embedInUrl(); // Embed the base state or the new state in the URL
  if (sort.value !== props.modelValue) {
    emits("update:modelValue", sort.value);
  }
  document.addEventListener("click", closeDropdownOnOutsideClick);
});

onUnmounted(() => {
  document.removeEventListener("click", closeDropdownOnOutsideClick);
});

const sort = ref(props.modelValue);

const selectOption = (option: string) => {
  sort.value = option;
  emits('update:modelValue', option);
  embedInUrl();
  toggleDropdownVisibility();
};

const extractFromUrl = () => {
  const sortParam = decodeURIComponent(router.currentRoute.value.query.sort as string || '');
  if (sortParam && props.sortOptions.includes(sortParam)) {
    sort.value = sortParam;
  }
};

const embedInUrl = () => {
  if (sort.value) {
    router.push({ query: { ...router.currentRoute.value.query, sort: encodeURIComponent(sort.value) } });
  } else {
    router.push({ query: { ...router.currentRoute.value.query, sort: undefined } });
  }
};
</script>

<template>
  <div id="dropdown">
    <div @click="toggleDropdownVisibility" class="button-container relative cursor-pointer">
      <div
        class="w-fit relative flex justify-center items-center gap-[.25rem] py-[.625rem] pl-[1.25rem] pr-[.625rem] bg-BGLight text-[15px] font-medium text-TextDark leading-[1.2] border-[1px] border-BGDark rounded-full z-[2] duration-[.15s] ease-in-out">
        {{ t(`sort.${modelValue}`) }}
        <i class="ri-arrow-down-s-line text-[1.5rem] h-[1rem] text-TextDark" :class="isDropdownOpen
      ? 'arrow-open'
      : 'arrow-close'
      "></i>
      </div>
      <div
        class="button-shadow h-[100%] w-[100%] absolute flex justify-center items-center py-[9px] pl-[1.125rem] pr-[.875rem] bg-BGDark text-[15px] font-medium border-[1px] border-BGDark rounded-full z-[1] duration-[.15s] ease-in-out" :class="isDropdownOpen
      ? 'top-0 left-0'
      : 'top-[2px] left-[2px]'
      ">
      </div>
    </div>
    <div v-if="isDropdownOpen" class="absolute mt-[.75rem] right-[.5rem]">
      <div
        class="w-fit relative flex flex-col items-end gap-[.5rem] py-[1rem] px-[1.5rem] bg-BGLight text-[15px] leading-[1.2] border-[1px] border-BGDark rounded-[16px] z-[2] ">
        <div v-for="(option, index) in sortOptions" :key="index" @click="selectOption(option)"
          class="cursor-pointer" :class="modelValue === option ? 'text-TextNormal' : 'text-TextDark'">{{
      t(`sort.${option}`) }}
        </div>
      </div>
      <div
        class="h-[100%] w-[100%] absolute top-[2px] left-[2px] flex flex-col items-end gap-[.5rem] py-[1rem] px-[1.5rem] bg-BGDark text-[15px] border-[1px] border-BGDark rounded-[16px] z-[1]">
      </div>
    </div>
  </div>
</template>

<style scoped>
.arrow-open {
  transform: translateY(-1px) rotateX(-180deg);
  transition: transform 0.25s ease-in-out;
}

.arrow-close {
  transform: translateY(.5px) rotateX(0deg);
  transition: transform 0.25s ease-in-out;
}

/* Hover isn't visible on touchscreen devices */
@media (hover: hover) {
  .button-container:hover .button-shadow {
    top: 0;
    left: 0;
  }
}

/* width */
::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.5rem;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--dtBorder);
}
</style>
