<script setup lang="ts">
import type {PropType} from "vue";

const props = defineProps({
  link: { // { title: "Facebook", url: "https://facebook.com" }
    type: Object as PropType<{ title: string, url: string }>,
    required: true
  }
});

const getTitle = () => {
  if (props.link.url.includes("facebook")) {
    return "Facebook";
  } else if (props.link.url.includes("instagram")) {
    return "Instagram";
  } else if (props.link.url.includes("youtube")) {
    return "YouTube";
  } else if (props.link.url.includes("pinterest")) {
    return "Pinterest";
  } else if (props.link.url.includes("tiktok")) {
    return "TikTok";
  } else if (props.link.url.includes("snapchat")) {
    return "Snapchat";
  } else if (props.link.url.includes("whatsapp")) {
    return "WhatsApp";
  } else if (props.link.url.includes("reddit")) {
    return "Reddit";
  } else {
    return "Link";
  }
}

const getIcon = () => {
  const name = getTitle();
  if (name === "Link") {
    return "ri-link";
  }
  if (name === "Facebook") {
    return "ri-facebook-circle-line";
  }
  return 'ri-' + name.toLowerCase() + '-line';
}
</script>

<template>
  <a :href="props.link.url" target="_blank">
    <i class="text-[1rem] h-[1rem]"
        :class="getIcon()"
    ></i>
    <span>{{ props.link.title || getTitle() }}</span>
  </a>
</template>

<style scoped>

</style>
