import {defineStore} from "pinia";
import {Drop} from "@/types/Drop";
import {Item} from "@/types/Item";

interface Breakpoint {
    width: number;
    height: number;
}

const desktopBreakpoint = 768;

export const useBreakpoint = defineStore("breakpoint", {
    state: (): Breakpoint => ({
        width: window.innerWidth,
        height: window.innerHeight
    }),
    getters: {
        isMobile(): boolean {
            return this.width < desktopBreakpoint;
        },
        isDesktop(): boolean {
            return this.width >= desktopBreakpoint;
        },
        getWidth(): number {
            return this.width;
        },
        getHeight(): number {
            return this.height;
        },
    },
    actions: {
        setWidth(width: number) {
            this.width = width;
        },
        setHeight(height: number) {
            this.height = height;
        }
    }
});
