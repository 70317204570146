<script setup lang="ts">
import {useBreakpoint} from "@/stores/Breakpoints";
import {useScreenDimensions} from "@/composables/useScreenWidth";
import { watch} from "vue";

// Keep track of screen dimensions
const breakpoint = useBreakpoint();
const {
  width, height
} = useScreenDimensions();
watch(width, () => {
  breakpoint.setWidth(width.value);
});
watch(height, () => {
  breakpoint.setHeight(height.value);
});

</script>

<template>
  <!-- tabindex="0" is needed to make select menus close when clicked outside -->
  <RouterView tabindex="0"/>
</template>

<style scoped>

</style>
