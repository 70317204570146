export function toCdnUrl(firebaseStorageUrl: string): string {
    if (!firebaseStorageUrl) {
        console.error("The URL is empty: " + firebaseStorageUrl);
        return firebaseStorageUrl;
    }
    const originUrl = "https://firebasestorage.googleapis.com/v0/b/otherlet-com.appspot.com/o/"
    if (!firebaseStorageUrl.startsWith(originUrl)) {
        //console.warn("The URL is not a Firebase Storage URL: " + firebaseStorageUrl);
        return firebaseStorageUrl;
    }
    const hostname = 'https://otherlet-com.b-cdn.net/';
    return firebaseStorageUrl.replace(originUrl, hostname);
}
