export enum CheckoutType {
    INSTAGRAM = "instagram",
}

export namespace CheckoutType {
    export const keys = ["instagram"];
    export const values: CheckoutType[] = [CheckoutType.INSTAGRAM];

    export function fromString(type: string): CheckoutType {
        switch (type.toLowerCase()) {
            case 'instagram':
                return CheckoutType.INSTAGRAM;
            default:
                throw new Error('Invalid CheckoutType: ' + type);
        }
    }
}
